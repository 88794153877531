import BackgroundImageLogin from '../../assets/images/background-login.avif';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input, Row, message } from 'antd';
import { useTitle } from '../../hooks/useTitle';
import { useEffect } from 'react';
import { validatePassword } from '../../utils/validators';
import axios from 'axios';

interface FormInput {
  password: string;
}

export default function ResetPasswordPage() {
  useTitle('Wijzig wachtwoord');
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_URL || '/api'
        }/user/verify-password-token/${token}`,
      )
      .catch((err) => {
        navigate('/login');
      });
  });

  const handleSubmit = async (values: FormInput) => {
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_URL || '/api'
        }/user/new-password/${token}`,
        values,
      )
      .then((res) => {
        messageApi.success('Wachtwoord gewijzigd');
        navigate('/login');
      })
      .catch((err) => {
        return messageApi.error('Er is iets misgegaan');
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        backgroundColor: '#C8C6D8',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${BackgroundImageLogin})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {contextHolder}
        <Card title="Wijzig wachtwoord" style={{ width: 500 }}>
          <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item
              name="password"
              label="Nieuw Wachtwoord"
              required
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password placeholder="********" maxLength={2000} />
            </Form.Item>
            <Form.Item
              style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Wijzigen
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Row>
  );
}
