export function enableDashboardTour() {
  localStorage.setItem('tour-dashboard-home', 'enabled');
  localStorage.setItem('tour-dashboard-start-screening', 'enabled');
  localStorage.setItem('tour-dashboard-screening-details', 'enabled');
  localStorage.setItem('tour-dashboard-users', 'enabled');
  localStorage.setItem('tour-dashboard-add-user', 'enabled');
}

/**
 * Disables the dashboard tour, if a key is provided only that part of the tour will be disabled
 * @param key The key of the tour to disable
 */
export function disableDashboardTour(key?: string) {
  if (key) {
    localStorage.removeItem(key);
  } else {
    localStorage.removeItem('tour-dashboard-home');
    localStorage.removeItem('tour-dashboard-start-screening');
    localStorage.removeItem('tour-dashboard-screening-details');
    localStorage.removeItem('tour-dashboard-users');
    localStorage.removeItem('tour-dashboard-add-user');
  }
}
