import { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Card,
  Typography,
  Popconfirm,
  Upload,
  Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMessage } from '../../../hooks/useMessage';
import { useStep } from '../../../hooks/useStep';
import { axiosInstance } from '../../../utils/axios';
import { RcFile } from 'antd/es/upload';
import VOGTypes from './VOGTypes.json';
import { ExternalLink } from '../../ExternalLink';
import { FileUrl } from '../../../shared/types/url.interface';
import { uploadFile } from '../../../utils/uploadFile';
import LoadingSpinner from '../util/LoadingSpinner';
import CommentField from '../../CommentField/CommentField';

const { Paragraph } = Typography;

function CertificateOfConductStep() {
  const [allowRequestForm] = Form.useForm();
  const [fileUploadForm] = Form.useForm();
  const [commentForm] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();
  const [cocType, setCocType] = useState<string | undefined>();

  const [hasAllowed, setHasAllowed] = useState(true);
  const [files, setFiles] = useState<RcFile[]>([]);
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [comment, setComment] = useState<string>('');

  const ERROR_MESSAGE = 'Er is een fout opgetreden, probeer het later opnieuw.';
  const FILE_ERROR_MESSAGE =
    'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.';
  const SUCCESS_MESSAGE = 'Succesvol opgeslagen';

  useEffect(() => {
    axiosInstance
      .get('/screening/certificate-of-conduct')
      .then((response) => {
        setHasAllowed(response.data.allowCertificateOfConductRequest);
        setCocType(
          VOGTypes.find(
            (item) => item.value === response.data.certificateOfConductType,
          )?.label,
        );
        allowRequestForm.setFieldValue(
          'certificateOfConductType',
          response.data.certificateOfConductType,
        );
        setComment(response.data.comment);
        setFileCount(response.data.pdfFilePath.length);
        setLoadingData(false);
      })
      .catch((error) => {
        if (
          error.response?.status === 404 &&
          error.response.data.message === 'No existing data found'
        ) {
          setHasAllowed(false);
          return;
        }
        toast.error('Er is een fout opgetreden, probeer het later opnieuw.');
        setLoadingData(false);
      });
  }, [allowRequestForm, commentForm, toast]);

  const handleAllowRequestSubmit = () => {
    axiosInstance
      .post('/screening/certificate-of-conduct/allow-request', {
        allowRequest: true,
        cocType: allowRequestForm.getFieldValue('certificateOfConductType'),
      })
      .then(() => {
        setHasAllowed(true);
        toast.success({
          content: 'Toestemming opgeslagen',
        });
      })
      .catch(() => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
      });
  };

  const handleFileUploadSubmit = async () => {
    setLoading(true);
    if (files[0] === undefined) {
      if (fileCount === 0) {
        setLoading(false);
        return toast.error('Upload minimaal 1 bestand');
      }
      return goToNextStep();
    }

    const fileAmount = files.length;
    await axiosInstance
      .get(`/screening/certificate-of-conduct/fileUrl/${fileAmount}`)
      .then((response) => {
        response.data.forEach((file: FileUrl, index: number) => {
          if (!file.url) {
            toast.error(FILE_ERROR_MESSAGE);
            throw new Error(FILE_ERROR_MESSAGE);
          }
          uploadFile(files[index], file.url)
            .then(() => {
              axiosInstance
                .post('screening/certificate-of-conduct/savefile', {
                  path: file.filePath,
                })
                .catch(() => {
                  toast.error(
                    `Opslaan van bestand ${files[index].name} is mislukt`,
                  );
                });
            })
            .catch(() => {
              toast.error(
                `Opslaan van bestand ${files[index].name} is mislukt`,
              );
            });
        });
        toast.success(SUCCESS_MESSAGE);
        setLoading(false);
        return goToNextStep();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(ERROR_MESSAGE);
        throw error;
      });
  };

  const proceedWithoutUpload = () => {
    if (!hasAllowed) {
      return toast.error(
        'Geef eerst toestemming om een VOG te laten aanvragen',
      );
    }
    goToNextStep();
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      <div>
        <Card title="Verklaring Omtrent Gedrag" style={{ maxWidth: 500 }}>
          <Paragraph style={{ display: 'inline' }}>
            U wordt gevraagd om een{' '}
            <ExternalLink
              link="https://www.justis.nl/producten/verklaring-omtrent-het-gedrag/wat-is-een-vog"
              text="Verklaring Omtrent Gedrag (VOG)"
            />{' '}
            te uploaden. Klik op de knop om toestemming te geven om de VOG van
            het aangegeven type aan te vragen met uw gegevens. Nadat u
            toestemming heeft gegeven, wordt de VOG digitaal klaargezet bij
            Justis. U ontvangt per email een betaallink (iDeal). Na betaling
            ontvangt u binnen enkele dagen (indien geen bezwaar) de VOG per post
            zodat u de VOG kunt uploaden.
            <p>
              <b>Let op:</b> Bij het aanvragen van de VOG zullen er kosten in
              rekening worden gebracht door de overheid.
            </p>
          </Paragraph>
          <Form
            form={allowRequestForm}
            onFinish={handleAllowRequestSubmit}
            layout="vertical"
          >
            <Divider />
            <Form.Item
              name="allowCertificateOfConductRequest"
              initialValue={true}
            >
              <p>
                Type VOG die wordt aangevraagd: <b>{cocType}</b>
              </p>
              <p>
                Ik geef toestemming dat er voor mij een Verklaring Omtrent
                Gedrag (VOG) van het aangegeven type wordt aangevraagd.
              </p>
              <Popconfirm
                placement="right"
                okText="Ja"
                cancelText="Nee"
                title="Let op!"
                description="
                    Dit is definitief en kan niet ongedaan gemaakt worden. Weet u zeker dat u toestemming geeft?"
                onConfirm={() => {
                  allowRequestForm.submit();
                }}
                overlayStyle={{ width: '250px' }}
              >
                <Button type="primary" disabled={hasAllowed}>
                  {hasAllowed ? 'Toestemming gegeven' : 'Geef toestemming'}
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
          {hasAllowed && (
            <Form form={fileUploadForm} onFinish={handleFileUploadSubmit}>
              <div>
                <p style={{ fontWeight: 'bold' }}>Bestanden:</p>
                <p className="fileAmount">
                  Aantal bestanden geüpload: {fileCount}
                </p>
                <Form.Item name="fileUpload">
                  <Upload
                    beforeUpload={(file) => {
                      setFiles((oldFiles) => [...oldFiles, file]);
                      return false;
                    }}
                    multiple={true}
                    accept=".pdf"
                    maxCount={5}
                    showUploadList={{ showRemoveIcon: false }}
                  >
                    <Button icon={<UploadOutlined />}>Selecteer bestand</Button>
                  </Upload>
                </Form.Item>
              </div>
            </Form>
          )}
          <Divider />
          <CommentField
            ApiUrl={'screening/certificate-of-conduct/comment'}
            comment={comment}
          />
        </Card>
        <Button
          style={{ float: 'left', marginTop: 20, marginBottom: 30 }}
          type="link"
          onClick={proceedWithoutUpload}
        >
          Voor nu doorgaan
        </Button>
        <Button
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
          type="primary"
          htmlType="submit"
          onClick={fileUploadForm.submit}
          loading={loading}
        >
          Opslaan & Volgende
        </Button>
      </div>
    </>
  );
}

export default CertificateOfConductStep;
