import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  AutoComplete,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Typography,
  message,
} from 'antd';
import { useTitle } from '../../hooks/useTitle';

interface ReferentInputs {
  relationToCandidate: string;
  reasonForUnreliability: string;
  comment: string;
}

const { Paragraph } = Typography;

const options = [
  {
    value: 'Leidinggevende',
  },
  {
    value: 'Hiërarchisch hoger geplaatste manager',
  },
  {
    value: 'HR Professional',
  },
  {
    value: 'Opdrachtgever',
  },
];

export default function EmployeeReferentPage() {
  useTitle('Referentie verklaring');
  const { token } = useParams();
  const [confirmation, setConfirmation] = useState<boolean>();
  const [candidatename, setCandidateName] = useState<string>('');
  const [unreliableCandidate, setUnreliableCandidate] = useState<
    boolean | null
  >(null);
  const [period, setPeriod] = useState<string>('');
  const [candidateRole, setCandidateRole] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/screening/employee-integrity/info-by-token/${token}`)
      .then((response) => {
        setCandidateRole(response.data.role);
        setPeriod(response.data.period);
        setCandidateName(response.data.name);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          if (error.response.data?.message === 'Referent not found') {
            return navigate('/error/referentNotFound');
          }
        }
      });
  }, [token, navigate]);

  const handleSubmit = async (values: ReferentInputs) => {
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_URL || '/api'
        }/screening/employee-integrity/info/${token}`,
        {
          confirmationOfEmployment: confirmation,
          unreliableCandidate: unreliableCandidate,
          reasonForUnreliability: values.reasonForUnreliability,
          comment: values.comment,
          relationToCandidate: values.relationToCandidate,
        },
      )
      .then(() => {
        navigate('/success/referentSuccess');
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          return messageApi.error('Ongeldige of al gebruikte link.');
        }
        messageApi.error('Er is iets misgegaan, probeer het later opnieuw');
      });
  };

  return (
    <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
      {contextHolder}
      <Card
        title="Referentie verklaring"
        style={{ marginTop: '25px', width: 500 }}
      >
        <Paragraph>
          Als onderdeel van de screeningsprocedure vragen wij aan de (voormalig)
          werkgevers/opdrachtgevers waar de kandidaat in de afgelopen twee jaar
          heeft gewerkt een verklaring over de betrouwbaarheid van de kandidaat.
          Vanuit de kandidaat hebben wij toestemming gekregen om u te benaderen.
          <br />
          <br />
          Het gaat om de volgende kandidaat: <b>{candidatename}</b>
        </Paragraph>

        <Divider />

        <Form onFinish={handleSubmit} layout="vertical" form={form}>
          <Form.Item
            name="relationToCandidate"
            label="Wat is uw relatie tot de kandidaat?"
            required
          >
            <AutoComplete
              options={options}
              placeholder="Functie"
              style={{ fontSize: '16px' }}
            >
              <Input maxLength={2000} />
            </AutoComplete>
          </Form.Item>
          <Form.Item
            name="confirmationOfEmployment"
            label={`Kunt u bevestigen dat de kandidaat gedurende ${period} als ${candidateRole} gewerkt heeft?`}
            rules={[
              {
                required: true,
                message:
                  'Vul alstublieft in of de huurder ooit te laat betaald heeft',
              },
            ]}
          >
            <Radio.Group
              onChange={(event: RadioChangeEvent) =>
                setConfirmation(event.target.value)
              }
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nee</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="unreliableCandidate"
            label={`Is er aanleiding om te twijfelen aan de betrouwbaarheid van de kandidaat in verband met deze functie?`}
            rules={[
              {
                required: true,
                message:
                  'Vul alstublieft in of er aanleiding is om te twijfelen',
              },
            ]}
          >
            <Radio.Group
              onChange={(event: RadioChangeEvent) =>
                setUnreliableCandidate(event.target.value)
              }
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nee</Radio>
            </Radio.Group>
          </Form.Item>
          {unreliableCandidate && (
            <Form.Item
              name="reasonForUnreliability"
              label="Reden voor twijfel aan betrouwbaarheid"
              required
            >
              <Input maxLength={2000} />
            </Form.Item>
          )}

          <Form.Item name="comment" label="Opmerkingen">
            <Input.TextArea rows={4} maxLength={2000} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Bevestigen
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
