import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Radio, Button, Divider } from 'antd';
import { StepToShow } from './index';
import { axiosInstance } from '../../../utils/axios';
import { useStep } from '../../../hooks/useStep';
import { useMessage } from '../../../hooks/useMessage';
import landlordStatementQuestions from './landlordStatementQuestions.json';
import LoadingSpinner from '../util/LoadingSpinner';
import CommentField from '../../CommentField/CommentField';
const { Paragraph } = Typography;

interface FormValues {
  questions: {
    [questionName: string]: string;
  };
}

const questions = landlordStatementQuestions.general;

const GeneralQuestions = ({
  setStepToShow,
  updateStepDisabled,
}: {
  setStepToShow: React.Dispatch<React.SetStateAction<StepToShow>>;
  updateStepDisabled: (stepEnum: StepToShow, disabled: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();
  const [comment, setComment] = useState<string>('');

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    questions: {},
  });

  const handleChange = (e: any, questionName: string) => {
    const newState = {
      ...formValues,
      questions: {
        ...formValues.questions,
        [questionName]: e.target.value,
      },
    };

    setFormValues(newState);
    if (questionName === 'priorRentedProperty' && e.target.value === true) {
      updateStepDisabled(StepToShow.RENTING, false);
      updateStepDisabled(StepToShow.REFERENT, false);
    } else if (
      (questionName === 'priorRentedProperty' && e.target.value === false) ||
      e.target.value === undefined
    ) {
      updateStepDisabled(StepToShow.RENTING, true);
      updateStepDisabled(StepToShow.REFERENT, true);
    }

    const allQuestionsFilled = Object.values(newState.questions).every(
      (question) =>
        question !== undefined && question !== '' && question !== null,
    );
    if (allQuestionsFilled) {
      axiosInstance
        .post('/screening/landlord-questions/general', newState.questions)
        .catch(() => {
          toast.error({
            content: `Er is een fout opgetreden, probeer het later opnieuw.`,
          });
        });
    }
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/landlord-questions/general')
      .then((response) => {
        if (response.data) {
          setComment(response.data.comment);
          setFormValues({
            questions: {
              priorRentedProperty: response.data.priorRentedProperty,
              propertyDenial: response.data.propertyDenial,
              propertyOwnership: response.data.propertyOwnership,
              criminalProsecution: response.data.criminalProsecution,
            },
          });
          form.setFieldsValue({
            comment: response.data.comment,
          });
        }
        if (response.data.priorRentedProperty === true) {
          updateStepDisabled(StepToShow.RENTING, false);
          updateStepDisabled(StepToShow.REFERENT, false);
        }
        setLoadingData(false);
      })
      .catch(() => {
        setLoadingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    setLoading(true);
    const allQuestionsFilled = Object.values(formValues.questions).every(
      (question) => question !== undefined && question !== '',
    );

    if (!allQuestionsFilled) {
      setLoading(false);
      return toast.error({
        content: 'Niet alle vragen zijn beantwoord.',
      });
    }

    if (formValues.questions.priorRentedProperty) {
      axiosInstance
        .post('/screening/landlord-questions/general', formValues.questions)
        .then(() => {
          toast.success({
            content: 'Gegevens opgeslagen',
          });
          setStepToShow(StepToShow.RENTING);
        })
        .catch(() => {
          toast.error({
            content: `Er is een fout opgetreden, probeer het later opnieuw.`,
          });
          setLoading(false);
        });
      return;
    }
    axiosInstance
      .post('/screening/landlord-questions/general', formValues.questions)
      .then(() => {
        toast.success({
          content: 'Gegevens opgeslagen',
        });
        goToNextStep();
      })
      .catch(() => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
        setLoading(false);
      });
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      <Form
        name="renting_questions"
        onFinish={onFinish}
        form={form}
        layout="vertical"
      >
        <Card title="Eigen verklaring - Algemeen" style={{ maxWidth: 500 }}>
          <Paragraph>
            Voor het huren van een object is een eigen verklaring vereist. Deze
            vragenlijst is bedoeld om een beeld te krijgen van de huurder, de
            vragen hieronder zijn van toepassing op iedereen. De andere twee
            stappen zijn alleen van toepassing op personen die een woning of
            bedrijfsruimte gehuurd hebben.
          </Paragraph>

          <b style={{ fontWeight: 'bold' }}>Vragen:</b>
          {questions.map((question) => (
            <Form.Item key={question.name}>
              <p>{question.question}</p>
              <Radio.Group
                onChange={(e) => handleChange(e, question.name)}
                value={formValues.questions[question.name]}
              >
                <Radio value={true}>Ja</Radio>
                <Radio value={false}>Nee</Radio>
              </Radio.Group>
            </Form.Item>
          ))}
          <Divider />
          <CommentField
            ApiUrl={'screening/landlord-questions/general/comment'}
            comment={comment}
          />
        </Card>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              float: 'right',
              marginTop: 20,
              marginBottom: 30,
              marginRight: 10,
            }}
            loading={loading}
          >
            Volgende
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GeneralQuestions;
