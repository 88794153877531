import { useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';

export function useDashBoardHomeTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTourSteps([
      {
        title: 'Credits',
        description:
          'Het aantal credits dat u heeft. Hiermee kunt u screenings starten.',
        target: getRef('credits')?.current,
      },
      {
        title: 'Start Screening',
        description: 'Klik hier om een screening te starten.',
        target: getRef('start-screening')?.current,
      },
      {
        title: 'Screening tabel',
        description: 'Hier ziet u een overzicht van alle screenings.',
        target: getRef('screening-table')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-home') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-home');
    }
  }, [isMobile, getRef]);

  return { tourSteps, setTourOpen, tourOpen };
}
