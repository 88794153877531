import { axiosInstance } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Divider, Form, Input, message } from 'antd';
import { useTitle } from '../../hooks/useTitle';
import {
  validateEmail,
  validateKVK,
  validatePassword,
  validatePhoneNumber,
} from '../../utils/validators';
import { enableDashboardTour } from '../../utils/dashboardTourFunctions';

interface RegisterInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  kvkNumber: string;
  phoneNumber: string;
}

export default function RegisterPage() {
  useTitle('Registreren');
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async (values: RegisterInputs) => {
    axiosInstance
      .post('/auth/register', values)
      .then(() => {
        messageApi.success('Account aangemaakt');
        enableDashboardTour();
        navigate('/register/2fa');
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          return messageApi.error(
            'Er bestaat al een account met dit email adres',
          );
        }
        if (
          err.response.status === 400 &&
          err.response.data.message === '2FA has already been setup'
        ) {
          return messageApi.error(
            'De twee factor authenticatie code is onjuist',
          );
        }
        return messageApi.error('Er is iets misgegaan');
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {contextHolder}
      <Card title="Registreren" style={{ width: 500 }}>
        <div>
          <p>
            Welkom bij CleaHR, hieronder maakt u een account aan voor u zelf, en
            een bedrijfsprofiel waar u later andere medewerkers kunt koppelen
            aan uw bedrijf.
          </p>
        </div>

        <Divider />

        <Form onFinish={handleSubmit} layout="vertical" form={form}>
          <Form.Item label="Naam" required>
            <Input.Group compact>
              <Form.Item name="firstName" noStyle required>
                <Input
                  placeholder="Voornaam"
                  style={{ width: '50%' }}
                  autoComplete="given-name"
                  maxLength={2000}
                />
              </Form.Item>
              <Form.Item name="lastName" noStyle required>
                <Input
                  placeholder="Achternaam"
                  style={{ width: '50%' }}
                  autoComplete="family-name"
                  maxLength={2000}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            required
            rules={[
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input
              placeholder="email@provider.com"
              autoComplete="email"
              maxLength={2000}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Wachtwoord"
            required
            rules={[
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              placeholder="********"
              autoComplete="new-password"
              maxLength={2000}
            />
          </Form.Item>
          <Divider />
          <Form.Item name="companyName" label="Bedrijfsnaam" required>
            <Input placeholder="CleaHR" maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="kvkNumber"
            label="KVK nummer"
            required
            rules={[{ validator: validateKVK }]}
          >
            <Input placeholder="KVK nummer" maxLength={20} />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Telefoonnummer"
            required
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input placeholder="0612345678" maxLength={50} />
          </Form.Item>
          <Form.Item
            style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
          >
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Registreren
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
