import React, { Dispatch, SetStateAction } from 'react';
import { Button, Dropdown } from 'antd';
import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';

export enum Filter {
  All = 'All',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export function ScreeningFilterButton({
  filter,
  setFilter,
}: {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
}) {
  const items = [
    {
      key: '1',
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {filter === Filter.All && <CheckCircleOutlined />}{' '}
          <span>Alle screenings</span>
        </div>
      ),
      onClick: () => {
        setFilter(Filter.All);
      },
    },
    {
      key: '2',
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {filter === Filter.NotStarted && <CheckCircleOutlined />}{' '}
          <span>Niet gestart</span>
        </div>
      ),
      onClick: () => {
        setFilter(Filter.NotStarted);
      },
    },
    {
      key: '3',
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {filter === Filter.InProgress && <CheckCircleOutlined />}{' '}
          <span>Bezig</span>
        </div>
      ),
      onClick: () => {
        setFilter(Filter.InProgress);
      },
    },
    {
      key: '4',
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {filter === Filter.Completed && <CheckCircleOutlined />}{' '}
          <span>Afgerond</span>
        </div>
      ),
      onClick: () => {
        setFilter(Filter.Completed);
      },
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button type="link">
        {filter === Filter.All
          ? 'Alle screenings'
          : filter === Filter.NotStarted
          ? 'Niet gestart'
          : filter === Filter.InProgress
          ? 'Bezig'
          : 'Afgerond'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
