import { Typography } from 'antd';

export function ExternalLink({
  link,
  text,
  style,
}: {
  link: string;
  text: string;
  style?: React.CSSProperties;
}) {
  return (
    <Typography.Link href={link} target="_blank" style={style}>
      {text}
    </Typography.Link>
  );
}
