import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Button, Card, Typography, Upload, Tag, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useStep } from '../../hooks/useStep';
import { axiosInstance } from '../../utils/axios';
import { uploadFile } from '../../utils/uploadFile';
import { useTitle } from '../../hooks/useTitle';
import CommentField from '../CommentField/CommentField';
import { useMessage } from '../../hooks/useMessage';
import LoadingSpinner from './util/LoadingSpinner';
import { centerPopup } from '../../utils/centerPopup';

const { Paragraph } = Typography;

interface BkrInputs {
  comment: string;
}

const BKRRegistrationStep = forwardRef((props, ref) => {
  useTitle('BKR Verificatie');
  const [form] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();
  const [file, setFile] = useState<File | null>(null);
  const [existingFile, setExistingFile] = useState<boolean>(false);

  const [loadingData, setLoadingData] = useState(true);
  const [loadingSaveFile, setLoadingSaveFile] = useState(false);
  const [comment, setComment] = useState<string>('');

  const openBkr = () => {
    const duoWindow = centerPopup(
      'https://mijnkredietoverzicht.bkr.nl/inloggen',
      '_blank',
      1080,
      800,
    );
    if (duoWindow) {
      duoWindow.focus();
    }
    if (!duoWindow) {
      toast.error(
        'Het openen van BKR is niet gelukt. Controleer uw pop-up instellingen.',
      );
    }
  };

  useImperativeHandle(ref, () => ({
    save() {
      const values = form.getFieldsValue();
      const touched = form.isFieldsTouched();
      if (!touched && !file) {
        return;
      }
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          handleSubmit(values, false);
        })
        .catch(() => {});
    },
  }));

  const handleSubmit = async (values: BkrInputs, showGoToNextStep: boolean) => {
    setLoadingSaveFile(true);
    if (file) {
      await axiosInstance
        .get('/screening/bkr/file/url')
        .then((response) => {
          const fileUrl = response.data.url;
          const path = response.data.filePath;

          if (!fileUrl) {
            toast.error(
              'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.',
            );
            return;
          }

          uploadFile(file, fileUrl)
            .then(() => {
              axiosInstance
                .post('screening/bkr/savefile', {
                  path,
                })
                .then(() => toast.success('Bestand opgeslagen'))
                .catch(() => {
                  toast.error(
                    'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.',
                  );
                  setLoadingSaveFile(false);
                  return;
                });
            })
            .catch(() => {
              toast.error(
                'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.',
              );
              setLoadingSaveFile(false);
              return;
            });
        })
        .catch(() => {
          toast.error('Er is een fout opgetreden, probeer het later opnieuw.');
          setLoadingSaveFile(false);
          return;
        });
      toast.success('BKR gegevens zijn succesvol opgeslagen');
      if (showGoToNextStep) {
        goToNextStep();
      }
      setLoadingSaveFile(false);
      goToNextStep();
    }
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/bkr/comment')
      .then((response) => {
        if (response) {
          setComment(response.data);
        }
        setLoadingData(false);
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
        setLoadingData(false);
      });
    axiosInstance
      .get('/screening/bkr/file/existing')
      .then((response) => {
        if (response && response.data != null) {
          setExistingFile(response.data);
        }
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw`,
        });
      });
  });
  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Form
        form={form}
        onFinish={(v) => handleSubmit(v, true)}
        layout="vertical"
      >
        <Card title="BKR Registratie" style={{ maxWidth: 500 }}>
          <Paragraph>
            Lees voordat u verder gaat eerst de volledige uitleg voor het
            downloaden van de BKR.
            <ul>
              <li>Open BKR en kies hier uw bank.</li>
              <li>
                Nadat u uw gegevens heeft opgegeven komt u op een
                overzichtspagina voor uw BKR registraties.
              </li>
              <li>
                Deze pagina heeft als titel "Welkom bij je kredietgegevens".
              </li>
              <li>Druk dan op CTRL + P voor Windows en ⌘ + P voor Mac OS.</li>
              <li>
                Sla het document op als pdf en daarna kunt u hieronder de pdf
                uploaden.
              </li>
            </ul>
          </Paragraph>

          <Button onClick={() => openBkr()} type="primary">
            Open BKR
          </Button>

          <Divider />

          <Form.Item label="BKR registraties PDF" name="fileUpload">
            <Upload
              beforeUpload={(file) => {
                setFile(file);
                return false;
              }}
              multiple={false}
              maxCount={1}
              accept=".pdf"
            >
              {existingFile && (
                <div>
                  <Tag color="success">Er is een bestand geüpload.</Tag>
                  <Paragraph italic>
                    Als dit niet het goede bestand is kunt u opnieuw een bestand
                    uploaden.
                  </Paragraph>
                </div>
              )}
              <Button
                icon={<UploadOutlined />}
                type={existingFile ? 'primary' : 'default'}
              >
                Selecteer bestand
              </Button>
            </Upload>
          </Form.Item>
          <Divider />
          <CommentField ApiUrl={'screening/bkr/comment'} comment={comment} />
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" loading={loadingSaveFile}>
              Opslaan
            </Button>
          </Form.Item>
        </Card>
        <Button
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
          type="primary"
          htmlType="submit"
          onClick={() => goToNextStep()}
        >
          Volgende
        </Button>
      </Form>
    </div>
  );
});

export default BKRRegistrationStep;
