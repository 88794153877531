import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';
import UsersTable from '../../../components/UserTable';
import useDynamicRefs from '../../../hooks/useDynamicRefs';
import { Flex, Tour } from 'antd';
import { useDashBoardUserTour } from '../../../hooks/tour/useDashboardUserTour';

const UserDashboard = () => {
  // tour variables
  const [getRef, setRef] = useDynamicRefs<HTMLElement>();
  const { tourSteps, tourOpen, setTourOpen } = useDashBoardUserTour(getRef);

  useTitle('Gebruikers');
  return (
    <DashboardLayout setTourOpen={setTourOpen}>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Flex vertical ref={setRef('users-table')}>
          <UsersTable />
        </Flex>
      </div>

      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </DashboardLayout>
  );
};

export default UserDashboard;
