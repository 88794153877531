import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';
import { axiosInstance } from '../../utils/axios';
import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd';
import { useDispatch } from 'react-redux';
import { verifyCode } from '../../redux/slices/candidateAuth.slice';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

function BeginScreening() {
  useTitle('Begin screening');
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [codeRequested, setCodeRequested] = useState<boolean>(false);
  const [loadingRequestCode, setLoadingRequestCode] = useState<boolean>(false);
  const [loadingVerifyCode, setLoadingVerifyCode] = useState<boolean>(false);

  useEffect(() => {
    if (!token) return notification.error({ message: 'Ongeldige token' });
    axiosInstance
      .get(`/screening/candidate/${token}`)
      .then((response) => {
        setPhoneNumber(response.data.phoneNumber);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          navigate('/error/tokenHasExpired');
        }
      });
  }, [token, navigate]);

  const requestSmsCode = () => {
    if (!token) return notification.error({ message: 'Ongeldige token' });
    setLoadingRequestCode(true);
    axiosInstance
      .post(`/candidate/auth/request-code/`, { token: token })
      .then(() => {
        setCodeRequested(true);
        setLoadingRequestCode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinish = (values: { code: string }) => {
    setLoadingVerifyCode(true);
    if (!token) return notification.error({ message: 'Ongeldige token' });

    dispatch(verifyCode({ code: values.code, token: token }) as any)
      .unwrap()
      .then(() => {
        navigate('/screening');
      })
      .catch((reason: any) => {
        notification.error({
          message: `${reason.message}`,
          placement: 'bottomRight',
          type: 'error',
        });
        setLoadingVerifyCode(false);
      });
  };

  return (
    <Row justify="center" align="middle" style={{ margin: '50px auto' }}>
      <Col xs={24} md={12} lg={8}>
        <Card
          title={
            <div style={{ marginBlock: 15 }}>
              <Text strong>Start Screening</Text>
              <Title level={3} style={{ marginBlock: 0 }}>
                Login met SMS Code
              </Title>{' '}
            </div>
          }
        >
          <div>
            <Text>
              Druk op de knop om een SMS code aan te vragen, deze zullen wij
              versturen naar <Text strong>{phoneNumber}</Text> Vervolgens kunt u
              de ontvangen code hieronder invullen en daarna beginnen met de
              screening.
            </Text>
          </div>
          <Button
            type="dashed"
            onClick={requestSmsCode}
            loading={loadingRequestCode}
            style={{ marginTop: 20 }}
          >
            Vraag een code aan
          </Button>
          {codeRequested && (
            <Form
              layout="vertical"
              style={{ marginTop: '20px' }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Verificatiecode"
                name="code"
                style={{ marginTop: '20px' }}
              >
                <Input placeholder="Bijv. 123456" maxLength={10} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                loading={loadingVerifyCode}
              >
                Bevestig code
              </Button>
            </Form>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default BeginScreening;
