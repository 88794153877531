import React from 'react';
import { Result } from 'antd';
import { useTitle } from '../../hooks/useTitle';

const NotFoundPage: React.FC = () => {
  useTitle('Pagina bestaat niet');
  return (
    <Result
      status="404"
      title="Pagina bestaat niet"
      subTitle="Sorry, de pagina die u probeert te bezoeken bestaat niet."
    />
  );
};

export default NotFoundPage;
