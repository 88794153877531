import { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/layout/dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Row,
  Button,
  Typography,
  Descriptions,
  Modal,
  Input,
  Form,
  notification,
  Tour,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../../utils/axios';
import ReferentTable from '../../../components/ReferentTable';
import { DateTime } from 'luxon';
import { ScreeningStep } from '../../../redux/slices/steps.slice';
import { availableSteps } from '../../../utils/allSteps';
import { Steps } from '../../../shared/enums/steps.enum';
import { EditOutlined } from '@ant-design/icons';
import { validateEmail } from '../../../utils/validators';
import StepStatusTable from '../../../components/StepStatusTable';
import SendNewInviteLink from '../../../components/ScreeningDetails/SendNewInviteLink';
import LoadingSpinner from '../../../components/screening-steps/util/LoadingSpinner';
import { useScreeningDetailsTour } from '../../../hooks/tour/useScreeningDetailsTour';

const { Title } = Typography;

interface Referent {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  completed: boolean;
  periodOfRenting: string;
  referentStatus: string;
}

interface Interpretation {
  completed: boolean;
  expert?: boolean;
  expertEmail?: string;
  email: string;
}

interface Screening {
  id: string;
  createdAt: string;
  status: string;
  candidate: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    periodOfRenting: string;
  };
  renterReferents?: Referent[];
  employeeReferents?: Referent[];
  interpretation?: Interpretation;
}

const initialScreening: Screening = {
  id: '',
  createdAt: '',
  status: '',
  candidate: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    periodOfRenting: '',
  },
  renterReferents: [],

  interpretation: {
    completed: false,
    email: '',
  },
};

const { Text } = Typography;

function ScreeningDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [screening, setScreening] = useState<Screening>(initialScreening);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = notification.useNotification();
  const [referentType, setReferentType] = useState<
    'employee' | 'landlord' | null
  >(null);
  const [referents, setReferents] = useState<Referent[]>([]);
  const [loadingData, setLoadingData] = useState(true);

  // tour variables
  const { tourSteps, tourOpen, setTourOpen } = useScreeningDetailsTour();

  useEffect(() => {
    if (
      screening.renterReferents &&
      screening.renterReferents.length > 0 &&
      screening.renterReferents[0].id !== ''
    ) {
      setReferents(screening.renterReferents);
      setReferentType('landlord');
    }
    if (
      screening.employeeReferents &&
      screening.employeeReferents.length > 0 &&
      screening.employeeReferents[0].id !== ''
    ) {
      setReferents(screening.employeeReferents);
      setReferentType('employee');
    }
  }, [screening]);

  useEffect(() => {
    axiosInstance
      .get(`/screening/info/${id}`)
      .then((response) => {
        setScreening(response.data);
      })
      .finally(() => setLoadingData(false));
  }, [id]);

  const updateReferents = (referents: Referent[]) => {
    referentType === 'landlord' &&
      setScreening((prevScreening: Screening | undefined) => {
        if (!prevScreening) return initialScreening;
        return {
          ...prevScreening,
          referents,
        };
      });
    referentType === 'employee' &&
      setScreening((prevScreening: Screening | undefined) => {
        if (!prevScreening) return initialScreening;
        return {
          ...prevScreening,
          employeeReferents: referents,
        };
      });
  };

  const formatStatus = (status: string): string => {
    const step = availableSteps.find(
      (step: ScreeningStep) => step.url === status,
    );
    if (step) {
      return step.title;
    }

    if (status === Steps.notStarted) {
      return 'Nog niet gestart';
    }

    if (status === Steps.completed) {
      return 'Afgerond';
    }
    return '';
  };

  function EditUserEmail() {
    const [form] = Form.useForm();
    async function submit() {
      const email = form.getFieldValue('email');
      if (!email || email === '') {
        return messageApi.error({
          message: 'Email is verplicht',
          placement: 'bottomRight',
        });
      }

      axiosInstance
        .put(`/screening/update/candidate-email/${id}`, {
          email: email,
        })
        .then(() => {
          setScreening({
            ...screening,
            candidate: {
              ...screening.candidate,
              email: email,
            },
          });
          setEditModalOpen(false);
          messageApi.success({
            message: 'Email is succesvol aangepast',
            placement: 'bottomRight',
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.message === 'Screening already started') {
              return messageApi.error({
                message:
                  'Screening is al gestart, u kunt het e-mailadres niet meer wijzigingen',
                placement: 'bottomRight',
              });
            }
            if (error.response.data.message === 'Email already exists') {
              return messageApi.error({
                message: 'Er bestaat al een kandidaat met dit e-mailadres',
                placement: 'bottomRight',
              });
            }
          }
          messageApi.error({
            message: 'Er is iets misgegaan, probeer het later nog eens',
            placement: 'bottomRight',
          });
        });
    }

    return (
      <Modal
        title="Bewerk e-mailadres"
        open={isEditModalOpen}
        onOk={submit}
        okText="Opslaan"
        cancelText="Annuleren"
        onCancel={() => setEditModalOpen(false)}
      >
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Text>
            U kunt het e-mailadres van de kandidaat hieronder bewerken, de
            uitnodiging voor de screening zal naar dit e-mailadres worden
            gestuurd. De uitnodiging die naar het oude e-mailadres is gestuurd
            zal niet meer werken.
          </Text>
        </div>
        <Form layout="vertical" form={form}>
          <Form.Item
            name="email"
            rules={[{ validator: validateEmail }]}
            label="Nieuw e-mailadres"
            initialValue={screening?.candidate.email}
          >
            <Input name="email" type="text" maxLength={2000} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  const formatInterpretation = (
    interpretation: Interpretation | undefined,
  ): string | undefined => {
    if (!interpretation) {
      return 'Duiding is niet aangevraagd';
    }
    if (interpretation.completed === true) {
      if (interpretation.expert) {
        return `Duiding gegeven door expert: ${interpretation.expertEmail}`;
      } else {
        return `Duiding gegeven door: ${interpretation.email}`;
      }
    }
    if (interpretation.completed === false) {
      if (interpretation.expert) {
        return `Gevraagd aan expert: ${interpretation.expertEmail}`;
      } else {
        return `Gevraagd aan: ${interpretation.email}`;
      }
    }
  };

  return (
    <DashboardLayout setTourOpen={setTourOpen}>
      {contextHolder}
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row
            justify="start"
            style={{ marginBottom: '1rem', marginTop: '1rem' }}
          >
            <Button type="link" onClick={() => navigate('/dashboard')}>
              <ArrowLeftOutlined /> Terug
            </Button>
          </Row>
          <Card
            title="Screening details"
            bordered={false}
            style={{ marginTop: 20 }}
          >
            <Descriptions
              title="Kandidaat Info"
              bordered
              size="small"
              column={1}
            >
              <Descriptions.Item label="Naam">
                {screening?.candidate.firstName} {screening?.candidate.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {screening?.candidate.email}
                {screening?.status === Steps.notStarted && (
                  <Button
                    onClick={() => setEditModalOpen(true)}
                    icon={<EditOutlined />}
                    size="small"
                    style={{ marginLeft: 10 }}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Telefoonnummer">
                {screening?.candidate.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Voortgang">
                {screening?.status && formatStatus(screening?.status)}
              </Descriptions.Item>
              <Descriptions.Item label="Duiding">
                {screening && formatInterpretation(screening?.interpretation)}
              </Descriptions.Item>
              <Descriptions.Item label="Start datum">
                {screening?.createdAt &&
                  DateTime.fromISO(screening?.createdAt).toFormat('dd-MM-yyyy')}
              </Descriptions.Item>
            </Descriptions>

            <SendNewInviteLink />

            <StepStatusTable screeningId={id} widthStyle="10%" />

            <Title level={5}>Referenten</Title>
            {referents && (
              <ReferentTable
                data={referents}
                setData={updateReferents}
                referentType={referentType}
              />
            )}
          </Card>
          <EditUserEmail />
        </>
      )}
      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </DashboardLayout>
  );
}

export default ScreeningDetails;
