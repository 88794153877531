import { notification } from 'antd';
import React, { createContext, useState } from 'react';

interface ErrorContextType {
  error: string;
  handleError: (errorMessage: string) => void;
}

const defaultErrorContextValue: ErrorContextType = {
  error: '',
  handleError: (errorMessage: string) => {
    notification.error({
      message: errorMessage,
      type: 'error',
      placement: 'bottomRight',
    });
  },
};

export const ErrorContext = createContext<ErrorContextType>(
  defaultErrorContextValue,
);

export const ErrorProvider = ({ children }: any) => {
  const [error, setError] = useState<string>('');

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
    notification.error({
      message: errorMessage,
      type: 'error',
      placement: 'bottomRight',
    });
  };

  return (
    <ErrorContext.Provider value={{ error, handleError }}>
      {children}
    </ErrorContext.Provider>
  );
};
