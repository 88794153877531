import { useEffect } from 'react';

export function useTitle(title: string, defaultAppended = ' - CleaHR') {
  useEffect(() => {
    const oldDocumentTitle = document.title;
    document.title = title + defaultAppended;
    return () => {
      document.title = oldDocumentTitle;
    };
  });
}
