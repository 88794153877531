import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../../utils/axios';
import { Button, Card, Form, Input, Modal, notification } from 'antd';
import { validateEmail } from '../../../utils/validators';
import { ArrowRightOutlined } from '@ant-design/icons';
import LoadingSpinner from '../../../components/screening-steps/util/LoadingSpinner';

interface Company {
  id: string;
  name: string;
  credits: number;
  kvkNumber: string;
  phoneNumber: string;
  email: string;
  expertInterpretationEmail: string;
}

const { confirm } = Modal;
const EditCompany = () => {
  useTitle('Bedrijven');
  const { id } = useParams();
  const [form] = Form.useForm();
  const [company, setCompany] = useState<Company | null>(null);
  const [messageApi, contextHolder] = notification.useNotification();

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`/company/info/${id}`)
      .then((res) => {
        form.setFieldsValue(res.data);
        setCompany(res.data);
      })
      .catch((err) => {
        messageApi.error({
          message:
            'Er is iets misgegaan bij het ophalen van de bedrijfsgegevens',
          placement: 'bottomRight',
        });
      })
      .finally(() => setLoadingData(false));
  }, [form, id, messageApi]);

  function differenceComponent(a: Company | null, bank: Company | null) {
    return (
      <div>
        <div>
          <span style={{ display: 'block' }}>Bedrijfsnaam</span>{' '}
          <span style={{ textDecoration: 'line-through' }}>{a?.name}</span>{' '}
          <ArrowRightOutlined /> <strong>{bank?.name}</strong>
        </div>
        <p>
          <span style={{ display: 'block' }}>KVK nummer</span>{' '}
          <span style={{ textDecoration: 'line-through' }}>{a?.kvkNumber}</span>{' '}
          <ArrowRightOutlined /> <strong>{bank?.kvkNumber}</strong>
        </p>
        <p>
          <span style={{ display: 'block' }}>Email</span>{' '}
          <span style={{ textDecoration: 'line-through' }}>{a?.email}</span>
          <ArrowRightOutlined /> <strong>{bank?.email}</strong>
        </p>
        <p>
          <span style={{ display: 'block' }}>Duiding's email</span>{' '}
          <span style={{ textDecoration: 'line-through' }}>
            {a?.expertInterpretationEmail}
          </span>{' '}
          <ArrowRightOutlined />{' '}
          <strong>{bank?.expertInterpretationEmail}</strong>
        </p>
      </div>
    );
  }

  function submitForm(values: Company) {
    confirm({
      title: 'Weet je zeker dat je deze wijzigingen wilt opslaan?',
      content: differenceComponent(company, values),
      onOk() {
        axiosInstance
          .put(`/company/update/${id}`, values)
          .then(() => {
            Modal.success({
              content: 'Bedrijf succesvol aangepast',
            });
          })
          .catch(() => {
            messageApi.error({
              message: 'Er is iets misgegaan, probeer het later nog eens',
              placement: 'bottomRight',
            });
          });
      },
      width: 800,
    });
  }
  return (
    <DashboardLayout>
      {contextHolder}
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <Card title={`${company?.name} bewerken`} style={{ marginTop: 20 }}>
          <Form layout="vertical" form={form} onFinish={submitForm}>
            <Form.Item label="Bedrijfsnaam" name="name">
              <Input maxLength={2000} />
            </Form.Item>
            <Form.Item label="KVK nummer" name="kvkNumber" required>
              <Input maxLength={20} />
            </Form.Item>
            <Form.Item label="Telefoon nummer" name="phoneNumber" required>
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item label="Email" name="email" required>
              <Input maxLength={2000} />
            </Form.Item>
            <Form.Item
              label="Email voor expert duiding"
              name="expertInterpretationEmail"
              rules={[{ validator: validateEmail }]}
              required
            >
              <Input type="email" maxLength={2000} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Opslaan
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </DashboardLayout>
  );
};

export default EditCompany;
