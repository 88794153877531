import { ReactNode } from 'react';
import { Layout } from 'antd';
import Navigation from '../Navigation';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import CleaHrLogo from '../../assets/logo/logo-no-bg.png';
import { Link } from 'react-router-dom';

interface DashboardLayoutProps {
  children: ReactNode;
  setTourOpen?: (value: boolean) => void;
}

function DashboardLayout({ children, setTourOpen }: DashboardLayoutProps) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <Link to="/dashboard">
          <img
            src={CleaHrLogo}
            alt="CleaHR logo"
            style={{
              display: 'flex',
              margin: 'auto',
              width: 20,
            }}
          />
        </Link>

        <Navigation setTourOpen={setTourOpen} />
      </Layout.Header>
      <Layout.Content
        style={{ width: isMobile ? '100%' : '80%', margin: '0 auto' }}
      >
        {children}
      </Layout.Content>
    </Layout>
  );
}

export default DashboardLayout;
