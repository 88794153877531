import { axiosInstance } from '../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Tooltip,
  notification,
  theme,
} from 'antd';
import { InfoCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTitle } from '../../hooks/useTitle';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';

interface RegisterInputs {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

const { useToken } = theme;

export default function EditUserComponent() {
  useTitle('Aanpassen gebruiker');
  const [messageApi, contextHolder] = notification.useNotification();
  const { token } = useToken();
  const [form] = Form.useForm();
  const [userEmail, setUserEmail] = useState<string>('');
  const [radioValue, setRadioValue] = useState('');
  const navigate = useNavigate();
  const params = useParams();

  const [loadingData, setLoadingData] = useState(true);

  const [radioOptions, setRadioOptions] = useState([
    { label: 'Onderzoeker', value: 'researcher', disabled: true },
    { label: 'Administrator', value: 'admin' },
  ]);

  const onRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setRadioValue(value);
  };

  useEffect(() => {
    const userId = params.id;
    axiosInstance
      .post('user/verify-admin', { userId: userId })
      .then((response) => {
        if (!response.data) {
          navigate('/dashboard/users');
        }
      })
      .finally(() => setLoadingData(false));
    axiosInstance
      .get(`user/get/${params.id}`)
      .then((response) => {
        form.setFieldsValue(response.data);
        if (!response.data.mainUser) {
          setRadioOptions([
            { label: 'Onderzoeker', value: 'researcher' },
            { label: 'Administrator', value: 'admin' },
          ]);
        }
        setUserEmail(response.data.email);
        setRadioValue(response.data.role);
        if (response.data.mainUser) {
          axiosInstance
            .post('user/verify-mainUser', { userId: userId })
            .then((response) => {
              if (!response.data) {
                navigate('/dashboard/users');
              }
            });
        }
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: RegisterInputs) => {
    values.email = userEmail;
    values.role = radioValue;
    axiosInstance
      .patch('/user/update', values)
      .then((res) => {
        messageApi.success({
          message: 'Account aangepast',
          placement: 'bottomRight',
        });
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          return messageApi.error({
            message:
              'Dit is de hoofdgebruiker en kan niet worden aangepast naar onderzoeker',
            placement: 'bottomRight',
          });
        }
        if (err.response?.status === 400) {
          if (
            err.response.data.message[0] ===
            'firstName must be longer than or equal to 2 characters'
          ) {
            return messageApi.error({
              message: 'Voornaam moet minimaal 2 karakters zijn',
              placement: 'bottomRight',
            });
          }
          if (
            err.response.data.message[0] ===
            'lastName must be longer than or equal to 2 characters'
          ) {
            return messageApi.error({
              message: 'Achternaam moet minimaal 2 karakters zijn',
              placement: 'bottomRight',
            });
          }
        }
        return messageApi.error({
          message: 'Er is iets misgegaan',
          placement: 'bottomRight',
        });
      });
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}
    >
      {contextHolder}
      <div>
        <Row
          justify="start"
          style={{ marginBottom: '1rem', marginTop: '1rem' }}
        >
          <Button type="link" onClick={() => navigate('/dashboard/users')}>
            <ArrowLeftOutlined /> Terug
          </Button>
        </Row>
        <Card title="Aanpassen gebruiker" style={{ maxWidth: 500 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <p>Email</p>
            <Input value={userEmail} disabled />
          </div>
          <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item label="Naam">
              <Input.Group compact>
                <Form.Item
                  name="firstName"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vul alstublieft een voornaam in.',
                    },
                    {
                      min: 2,
                      message: 'Voornaam moet minimaal 2 karakters bevatten.',
                    },
                  ]}
                >
                  <Input placeholder="Voornaam" style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  noStyle
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vul alstublieft een voornaam in.',
                    },
                    {
                      min: 2,
                      message: 'Voornaam moet minimaal 2 karakters bevatten.',
                    },
                  ]}
                >
                  <Input placeholder="Achternaam" style={{ width: '50%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Soort gebruiker" name="role" required>
              <Radio.Group
                options={radioOptions}
                onChange={onRadioChange}
                optionType="button"
                buttonStyle="solid"
                value={radioValue}
              />
              <Tooltip
                title={
                  <div>
                    <p>
                      <b>Wat is het verschil?</b>
                    </p>
                    <p>Onderzoeker:</p>
                    <ul>
                      <li>Kan een screening starten, inzien en eindigen.</li>
                      <li>Kan alle openstaande screenings zien.</li>
                      <li>
                        Kan{' '}
                        <b>
                          <u>geen</u>
                        </b>{' '}
                        andere gebruikers inzien.
                      </li>
                    </ul>
                    <p>Administrator:</p>
                    <ul>
                      <li>Heeft dezelfde rechten als de gebruiker, én</li>
                      <li>
                        Kan gebruikers en administrators aanmaken, aanpassen en
                        verwijderen.
                      </li>
                    </ul>
                  </div>
                }
                overlayStyle={{ maxWidth: 'none' }}
                color={token.colorPrimary}
              >
                <InfoCircleOutlined style={{ marginLeft: '1%' }} />
              </Tooltip>
            </Form.Item>
            <Form.Item
              style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Aanpassen
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}
