import { RefObject, createRef } from 'react';

const map = new Map<string, RefObject<unknown>>();

function setRef<T>(key: string): RefObject<T> {
  const ref = createRef<T>();
  map.set(key, ref);
  return ref;
}

function getRef<T>(key: string): RefObject<T> {
  return map.get(key) as RefObject<T>;
}

function useDynamicRefs<T>(): [
  (key: string) => RefObject<T>,
  (key: string) => RefObject<T>,
] {
  return [getRef, setRef];
}

export default useDynamicRefs;
