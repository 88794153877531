import { useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';

export function useStartScreeningTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTourSteps([
      {
        title: 'Algemene kandidaat informatie',
        description:
          'Om een screening te starten heeft u de volgende informatie nodig: voornaam, achternaam, mobiel nummer en emailadres.',
        target: getRef('general')?.current,
      },
      {
        title: 'Duiding',
        description:
          "U kunt extra duiding aanvragen bij deze screening. Deze check kan gedaan worden door een expert of door een partij naar keuze. Voor duiding door een expert van dit platform worden 4 extra credits in rekening gebracht. Wilt u uw duiding door een andere expert laten doen, neem dan contact op met 'info@cleahr.com'",
        target: getRef('interpretation')?.current,
      },
      {
        title: 'Type screening',
        description:
          'Selecteer de checks die u wilt uitvoeren bij deze screening. U kunt meerdere checks selecteren. U kunt ook gebruik maken van de presets.',
        target: getRef('checks')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-start-screening') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-start-screening');
    }
  }, [isMobile, getRef]);

  return { tourSteps, setTourOpen, tourOpen };
}
