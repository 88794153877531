import { axiosInstance } from '../../utils/axios';
import BackgroundImageLogin from '../../assets/images/background-login.avif';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Form, Input, Row, message } from 'antd';
import { useTitle } from '../../hooks/useTitle';
import { useEffect } from 'react';
import { validatePassword } from '../../utils/validators';
import { enableDashboardTour } from '../../utils/dashboardTourFunctions';

interface FormInput {
  password: string;
}

export default function ConfirmSubUserPage() {
  useTitle('Bevestig account');
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get(`user/verify-password-token/${token}`).catch((err) => {
      navigate('/login');
    });
  }, [token, navigate, messageApi]);

  const handleSubmit = async (values: FormInput) => {
    axiosInstance
      .post(`user/new-password/${token}`, values)
      .then((res) => {
        messageApi.success('Account bevestigd');
        enableDashboardTour();
        navigate('/register/2fa');
      })
      .catch((err) => {
        return messageApi.error('Er is iets misgegaan');
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        backgroundColor: '#C8C6D8',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${BackgroundImageLogin})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {contextHolder}
        <Card title="Bevestig account" style={{ maxWidth: 500 }}>
          <div>
            <p>
              Welkom bij CleaHR, hieronder kunt u uw account bevestigen door een
              wachtwoord te kiezen. Daarna wordt u gevraagd om een 2FA in te
              stellen.
            </p>
          </div>

          <Divider />

          <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item
              name="password"
              label="Wachtwoord"
              required
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                placeholder="********"
                autoComplete="new-password"
                maxLength={2000}
              />
            </Form.Item>
            <Form.Item
              style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Bevestigen
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Row>
  );
}
