import { Result } from 'antd';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';

interface StatusMessages {
  [status: string]: {
    title: string;
    subtitle: string;
  };
}

const statusMessages: StatusMessages = {
  referentNotFound: {
    title: 'Referentieverklaring niet gevonden',
    subtitle:
      'Deze referentie verklaring is niet meer geldig, u kunt deze pagina nu sluiten.',
  },
  interpretationNotFound: {
    title: 'Duiding niet gevonden',
    subtitle:
      'Deze link voor het geven van duiding bestaat niet (meer), dit kan komen omdat er al duiding gegeven is.',
  },
  loginCandidate: {
    title: 'Sessie verlopen',
    subtitle:
      'Het lijkt erop dat uw sessie is verlopen. Log alstublieft opnieuw in met de link uit de uitnodigings email',
  },
  tokenHasExpired: {
    title: 'Link verlopen',
    subtitle:
      'Uw link is verlopen. Dit kan komen door dat u de screening al heeft afgerond of dat de screening is verwijderd.',
  },
  finalReportNotFound: {
    title: 'Eindrapport niet gevonden',
    subtitle:
      'Het lijkt erop dat het eindrapport niet meer beschikbaar is, dit kan komen doordat de screening automatisch is verwijderd na 30 dagen dat de screening is afgerond.',
  },
};

function ErrorPage() {
  useTitle('Er is iets misgegaan');
  const { status } = useParams();

  if (!status) return <Navigate to="/404" />;
  if (!statusMessages[status]) return <Navigate to="/404" />;

  return (
    <Result
      status="error"
      title={statusMessages[status].title}
      subTitle={statusMessages[status].subtitle}
    />
  );
}

export default ErrorPage;
