import { useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';

export function useDashBoardAddUserTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTourSteps([
      {
        title: 'Registreren gebruiker',
        description: 'Hier kunt u een account aanmaken voor uw medewerkers.',
        target: getRef('card')?.current,
      },
      {
        title: 'Naam',
        description:
          'Vul hier de voornaam en achternaam van de gebruiker in. Dit kan later nog aangepast worden.',
        target: getRef('name')?.current,
      },
      {
        title: 'Email',
        description: 'Vul hier het email adres van de gebruiker in.',
        target: getRef('email')?.current,
      },
      {
        title: 'Rollen',
        description:
          'Hier kunt u kiezen of de gebruiker een onderzoeker of administrator is. Voor meer informatie over de rollen, beweeg uw muis op het informatie icoon.',
        target: getRef('role')?.current,
      },
      {
        title: 'Aanmaken',
        description:
          'Klik hier om het account aan te maken. De gebruiker zal een mail krijgen om het account te bevestigen.',
        target: getRef('submit')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-add-user') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-add-user');
    }
  }, [isMobile, getRef]);

  return { tourSteps, setTourOpen, tourOpen };
}
