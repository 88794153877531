import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../../utils/axios';
import { Card, Row, Col, Typography, Divider, Button } from 'antd';
import { useMessage } from '../../../hooks/useMessage';

const { Text } = Typography;

function DownloadFinalReportPage() {
  const { token } = useParams();
  const { toast } = useMessage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token) return;
    axiosInstance.get(`/screening/validate-final-token/${token}`).catch(() => {
      navigate('/error/finalReportNotFound');
    });
  }, [token, navigate]);

  function downloadReport() {
    if (!token) return;
    setLoading(true);
    axiosInstance
      .get(`/screening/generate-final-pdf/${token}`)
      .then((response) => {
        setLoading(false);
        window.open(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          'Er is iets fout gegaan bij het downloaden van het rapport',
        );
        console.error(error);
      });
  }

  return (
    <Row justify="center" align="middle" style={{ margin: '50px auto' }}>
      <Col xs={24} md={12} lg={8}>
        <Card title="Download eindrapport">
          <Text>
            U kunt het eindrapport downloaden door op de onderstaande knop te
            klikken. Het rapport wordt automatisch gedownload. Sla dit op een
            veilige plek op. U kunt het rapport tot 30 dagen na het afronden van
            de screening downloaden.
          </Text>
          <Divider />
          <Button
            type="primary"
            block
            onClick={() => downloadReport()}
            loading={loading}
          >
            Download eindrapport
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

export default DownloadFinalReportPage;
