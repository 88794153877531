import { useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';

export function useScreeningDetailsTour() {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTourSteps([
      {
        title: 'Informatie van de screening',
        description:
          'Hier ziet u alle informatie over de screening, onder andere de info van de kandidaat, de voltooide stappen en de door de kandidaat opgegeven referenten.',
        target: null,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-screening-details') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-screening-details');
    }
  }, [isMobile]);

  return { tourSteps, setTourOpen, tourOpen };
}
