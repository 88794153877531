import axios from 'axios';

const axiosLoginInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});
async function login(
  email: string,
  password: string,
  twoFactorAuthenticationCode: string,
) {
  const response = await axiosLoginInstance.post(
    '/auth/login',
    {
      email,
      password,
      twoFactorAuthenticationCode,
    },
    { withCredentials: true },
  );

  if (response.data.user) {
    localStorage.removeItem('candidate');
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response.data.user;
}

async function login2fa(email: string, password: string) {
  const hasTwoFactor = await axiosLoginInstance.post(
    '/auth/login-2fa',
    {
      email,
      password,
    },
    { withCredentials: true },
  );
  return hasTwoFactor?.data;
}

function getCurrentUser() {
  const user = localStorage.getItem('user');
  if (!user) return;
  return JSON.parse(user);
}

async function logout() {
  axiosLoginInstance
    .post('/auth/logout')
    .then(() => {
      localStorage.removeItem('user');
    })
    .catch(() => console.error('Logout failed'));
}

export const AuthService = { login, getCurrentUser, login2fa, logout };
