import React from 'react';
import { ConfigProvider } from 'antd';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LogIn from './pages/auth/log-in';
import DashboardHome from './pages/dashboard/home';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import Screening from './pages/screening';
import StartScreeningScreen from './pages/dashboard/start-screening/start-screening';
import BeginScreening from './pages/BeginScreening';
import { AuthenticatedCandidateRoute } from './components/AuthenticatedCandidateRoute';
import LandlordReferentPage from './pages/referent/landlord';
import { Navigate } from 'react-router';
import SuccesPage from './pages/status/success';
import NotFoundPage from './pages/status/404';
import ErrorPage from './pages/status/error';
import ScreeningDetails from './pages/dashboard/screening-details';
import InterpretationPage from './pages/interpretation';
import RegisterPage from './pages/auth/register';
import TwoFactorSetup from './pages/auth/2fa';
import CompanyDashboard from './pages/dashboard/company';
import RegisterSubUserPage from './pages/dashboard/create-sub-user';
import UserDashboard from './pages/dashboard/users';
import ConfirmSubUserPage from './pages/auth/confirm-sub-user';
import UserRole from './shared/enums/user-role.enum';
import EditUser from './pages/dashboard/users/edit-user';
import DownloadFinalReportPage from './pages/dashboard/download-final-report';
import ForgotPasswordPage from './pages/auth/forgot-password';
import ResetPasswordPage from './pages/auth/reset-password';
import { App as AntdApp } from 'antd';
import EditCompany from './pages/dashboard/company/edit';
import useAxiosInterceptor from './utils/axios';
import { ErrorProvider } from './context/ErrorProvider';
import EmployeeReferentPage from './pages/referent/employee';

function App() {
  useAxiosInterceptor();

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: '#05205F', colorFillSecondary: '#C6C7D9' },
          components: {
            Menu: {
              colorBgBase: '#001529',
              horizontalItemSelectedColor: 'white',
              horizontalItemHoverColor: '#1890ff',
              itemHoverColor: '#808080',
            },
            Table: {
              headerSortActiveBg: '#fafafa',
            },
          },
        }}
      >
        <ErrorProvider>
          <AntdApp>
            <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/login" element={<LogIn />} />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPasswordPage />}
                />
                <Route
                  path="/dashboard/start-screening"
                  element={
                    <AuthenticatedRoute>
                      <StartScreeningScreen />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard/screening/:id"
                  element={
                    <AuthenticatedRoute>
                      <ScreeningDetails />
                    </AuthenticatedRoute>
                  }
                />

                <Route
                  path="/screening/start/:token"
                  element={<BeginScreening />}
                />
                <Route path="/success/:status" element={<SuccesPage />} />
                <Route path="/error/:status" element={<ErrorPage />} />
                <Route
                  path="/screening"
                  element={
                    <AuthenticatedCandidateRoute>
                      <Screening />
                    </AuthenticatedCandidateRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <AuthenticatedRoute>
                      <DashboardHome />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard/company"
                  element={
                    <AuthenticatedRoute superAdminRoute>
                      <CompanyDashboard />
                    </AuthenticatedRoute>
                  }
                />

                <Route
                  path="/dashboard/users"
                  element={
                    <AuthenticatedRoute role={UserRole.ADMIN}>
                      <UserDashboard />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard/users/:id"
                  element={
                    <AuthenticatedRoute role={UserRole.ADMIN}>
                      <EditUser />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard/company"
                  element={
                    <AuthenticatedRoute superAdminRoute>
                      <CompanyDashboard />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard/company/edit/:id"
                  element={
                    <AuthenticatedRoute superAdminRoute>
                      <EditCompany />
                    </AuthenticatedRoute>
                  }
                />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/register/2fa" element={<TwoFactorSetup />} />
                <Route
                  path="/dashboard/register/sub-user"
                  element={
                    <AuthenticatedRoute>
                      <RegisterSubUserPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/confirm/sub-user/:token"
                  element={<ConfirmSubUserPage />}
                />
                <Route
                  path="/referent/:token"
                  element={<LandlordReferentPage />}
                />
                <Route
                  path="/referent/landlord/:token"
                  element={<LandlordReferentPage />}
                />
                <Route
                  path="/referent/employer/:token"
                  element={<EmployeeReferentPage />}
                />

                <Route
                  path="/interpretation/:token"
                  element={<InterpretationPage />}
                />
                <Route
                  path="/screening/download/:token"
                  element={<DownloadFinalReportPage />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </AntdApp>
        </ErrorProvider>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
