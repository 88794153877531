import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  theme,
  Descriptions,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { useStep } from '../../hooks/useStep';
import { axiosInstance } from '../../utils/axios';
import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useTitle } from '../../hooks/useTitle';
import { useMessage } from '../../hooks/useMessage';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const { useToken } = theme;

interface KvkData {
  kvkNumber: string;
  companyName: string;
  establishmentStreet: string;
  establishmentHouseNumber: string;
  establishmentCity: string;
  legalForm: string;
  companyEmail: string;
  companyPhoneNumber: string;
}
const KvkCheck = forwardRef((props, ref) => {
  useTitle('KvK Gegevens');
  const { token } = useToken();
  const { goToNextStep } = useStep();
  const { toast } = useMessage();
  const [kvk, setKvk] = useState<KvkData>();
  const [form] = Form.useForm();
  const [hasCompany, setHasCompany] = useState<Boolean>();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    axiosInstance
      .get('/screening/kvk/')
      .then((response) => {
        if (response.data) {
          setComment(response.data.KVKComment);
          setHasCompany(response.data.hasCompany);
        }
        if (response.data.kvkNumber) {
          setKvk(response.data);
        }
        setLoadingData(false);
      })
      .catch((error) => {
        toast.error('Er is iets misgegaan');
        setLoadingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    save() {
      const values = form.getFieldsValue();
      const touched = form.isFieldsTouched();
      if (!touched) {
        return;
      }
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          saveKVK(values?.kvk || '');
        })
        .catch(() => {});
    },
  }));

  function saveKVK(kvknumber: string) {
    setButtonLoading(true);
    axiosInstance
      .post('/screening/kvk', {
        hasCompany: hasCompany,
        kvkNumber: kvknumber,
      })
      .then((res) => {
        setButtonLoading(false);
        setKvk(res.data);
        toast.success({
          content: 'Gegevens opgeslagen',
        });
      })
      .catch((error) => {
        setButtonLoading(false);

        if (error.response.data.message === 'Invalid KVK number')
          return toast.error('KvK-nummer is ongeldig');
        return toast.error('Er is iets misgegaan');
      });
  }

  function handleNextStep() {
    setLoading(true);
    if (typeof hasCompany === 'boolean') {
      if (kvk?.kvkNumber === undefined && hasCompany === true) {
        setLoading(false);
        return toast.error('Vul een KvK-nummer in en verifieer deze');
      }
      saveKVK(kvk?.kvkNumber || '');
    }
    goToNextStep();
  }

  const onHasObjectChange = (event: RadioChangeEvent) => {
    setHasCompany(event.target.value);
  };

  function KvkInfo({ kvk }: { kvk: KvkData }) {
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (
      <Descriptions
        title="KvK Info"
        colon={false}
        column={1}
        layout={isMobile ? 'vertical' : 'horizontal'}
      >
        <Descriptions.Item label="KvK-nummer">
          {kvk?.kvkNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Bedrijfsnaam">
          {kvk?.companyName}
        </Descriptions.Item>
        <Descriptions.Item label="Adres">
          {kvk?.establishmentStreet} {kvk?.establishmentHouseNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Plaats">
          {kvk?.establishmentCity}
        </Descriptions.Item>
        <Descriptions.Item label="Rechtsvorm">
          {kvk?.legalForm}
        </Descriptions.Item>
        <Descriptions.Item label="Telefoonnummer">
          {kvk?.companyPhoneNumber}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Card
        title="KvK check"
        style={{
          maxWidth: 500,
          backgroundColor: token.colorBgBase,
        }}
      >
        <Form
          layout="vertical"
          onFinish={(values) => saveKVK(values.kvk)}
          form={form}
          initialValues={{ hasCompany: hasCompany }}
        >
          <Form.Item
            label="Bent u eigenaar van een geregistreerd bedrijf met een bijhorend KvK-nummer?"
            name="hasCompany"
            rules={[
              {
                required: true,
                message:
                  'Vul alstublieft in of u eigenaar bent van een bedrijf.',
              },
            ]}
          >
            <Radio.Group
              onChange={onHasObjectChange}
              defaultValue={hasCompany}
              value={hasCompany}
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nee</Radio>
            </Radio.Group>
          </Form.Item>
          {hasCompany && (
            <>
              <Form.Item
                label="KvK-nummer"
                name="kvk"
                rules={[
                  {
                    required: true,
                    message: 'Vul alstublieft een KvK-nummer in',
                  },
                  {
                    pattern: new RegExp('^[0-9]{8}$'),
                    message:
                      'Gebruik alstublieft het juiste formaat voor het KvK-nummer',
                  },
                ]}
              >
                <Input
                  placeholder="Bijvoorbeeld 12345678"
                  style={{ width: '100%' }}
                  maxLength={20}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={buttonLoading}>
                Verifieer
              </Button>
            </>
          )}
        </Form>

        {kvk && (
          <>
            <Divider />
            <KvkInfo kvk={kvk} />
          </>
        )}
        <Divider />
        <CommentField ApiUrl={'screening/kvk/comment'} comment={comment} />
      </Card>
      <Button
        style={{
          float: 'right',
          marginTop: 20,
          marginBottom: 30,
          marginRight: 10,
        }}
        type="primary"
        onClick={() => handleNextStep()}
        loading={loading}
      >
        Volgende
      </Button>
    </div>
  );
});

export default KvkCheck;
