import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import { Descriptions, DescriptionsProps, notification } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ScreeningConfigInterface } from '../../shared/interfaces/screeningConfig.interface';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { stepTitles } from '../../shared/enums/steps.enum';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';

interface stepsStatus {
  id: string;
  personaDetails: boolean;
  identification: boolean;
  bankData: boolean;
  incomeCheck: boolean;
  kvk: boolean;
  bkr: boolean;
  kadaster: boolean;
  landlordStatement: boolean;
  certificateOfConduct: boolean;
  screening: {
    id: string;
    allReferentsCompleted: boolean;
    confirmScreeningHonest: boolean;
    interpretation: {
      id: string;
      completed: boolean;
    };
  };
}
function StepStatusTable({
  screeningId,
  getScreening,
  disableReferentsInterpretation,
  disableConfirmation,
  widthStyle,
}: {
  screeningId?: string | undefined;
  getScreening?: boolean;
  disableReferentsInterpretation?: boolean;
  disableConfirmation?: boolean;
  widthStyle: string;
}) {
  const [descriptionItems, setDescriptionItems] =
    useState<DescriptionsProps['items']>();
  const [messageApi, contextHolder] = notification.useNotification();

  const [loadingData, setLoadingData] = useState(true);

  function CheckOrCloseIcon(value: boolean | undefined) {
    return value ? (
      <CheckOutlined style={{ color: 'green' }} />
    ) : (
      <CloseOutlined style={{ color: 'red' }} />
    );
  }

  function createDescriptionItem(
    label: string,
    status: boolean | undefined,
  ): DescriptionsItemType {
    return {
      label: label,
      children: CheckOrCloseIcon(status),
    };
  }

  useEffect(() => {
    let configuredSteps: string[] = [];

    if (!screeningId && !getScreening) {
      return;
    }

    let fetchScreeningId: string | undefined;
    fetchScreeningId = getScreening ? 'current' : screeningId;

    axiosInstance
      .get(`/screening/info/${fetchScreeningId}`)
      .then((response) => {
        if (!response.data) {
          messageApi.error({
            message:
              'Er is iets fout gegaan met het ophalen van de screening configuratie',
            placement: 'bottomRight',
          });
        }

        const config: ScreeningConfigInterface = response.data?.screeningConfig;
        configuredSteps = Object.keys(config).filter(
          (element) =>
            config[element as keyof ScreeningConfigInterface] === true,
        );

        axiosInstance
          .get(`screeningStepStatus/${fetchScreeningId}`)
          .then((response) => {
            if (!response.data) {
              messageApi.error({
                message:
                  'Er is iets fout gegaan bij het ophalen van de voltooide stappen',
                placement: 'bottomRight',
              });
            }

            const status: stepsStatus = response?.data;

            let descriptionItems: DescriptionsItemType[] = [];

            descriptionItems.push(
              createDescriptionItem(
                stepTitles.personalData,
                status.personaDetails,
              ),
            );

            for (const configuredStep of configuredSteps) {
              descriptionItems.push(
                createDescriptionItem(
                  stepTitles[configuredStep as keyof typeof stepTitles],
                  status[configuredStep as keyof stepsStatus] as boolean,
                ),
              );
            }

            if (!disableReferentsInterpretation) {
              if (status.screening.allReferentsCompleted != null) {
                descriptionItems.push(
                  createDescriptionItem(
                    stepTitles.referents,
                    status.screening.allReferentsCompleted,
                  ),
                );
              }

              if (
                status.screening.interpretation &&
                status.screening.interpretation.completed != null
              ) {
                descriptionItems.push(
                  createDescriptionItem(
                    stepTitles.interpretation,
                    status.screening.interpretation.completed,
                  ),
                );
              }
            }

            if (!disableConfirmation) {
              descriptionItems.push(
                createDescriptionItem(
                  'Bevestigd',
                  status.screening.confirmScreeningHonest,
                ),
              );
            }

            setDescriptionItems(descriptionItems);
            setLoadingData(false);
          })
          .catch((error) => {
            if (error?.response.data.message === 'Screening not found') {
              messageApi.warning({
                message: 'Screening stap status is niet gevonden',
                placement: 'bottomRight',
              });
            } else {
              throw error;
            }
            setLoadingData(false);
          });
      })
      .catch((error) => {
        if (error?.response.data.message === 'Screening not found') {
          messageApi.warning({
            message: 'Screening stap status is niet gevonden',
            placement: 'bottomRight',
          });
        } else {
          throw error;
        }
        setLoadingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      {contextHolder}
      <Descriptions
        title="Voltooide stappen"
        bordered
        size="small"
        style={{ marginTop: 20 }}
        contentStyle={{ width: widthStyle, textAlign: 'center' }}
        column={1}
        items={descriptionItems}
      />{' '}
    </>
  );
}

export default StepStatusTable;
