import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  theme,
  notification,
  Modal,
} from 'antd';
import CleaHrLogo from '../../assets/logo/logo.jpg';
import BackgroundImageLogin from '../../assets/images/background-login.avif';
import { useTitle } from '../../hooks/useTitle';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/auth.slice';
import { AuthService } from '../../redux/services/auth.service';
import OtpInput from 'react-otp-input';

const { useToken } = theme;

const LoginScreen = () => {
  useTitle('Log in');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [formItems, setFormItems] = useState<Fields>();

  const { token } = useToken();
  const navigate = useNavigate();

  const onFinish = (values: Fields) => {
    AuthService.login2fa(values.email, values.password)
      .then((res) => {
        if (!res) {
          navigate('/register/2fa');
        }
      })
      .catch((error) => {
        if (!error?.response) {
          api.error({
            message: 'Er is een probleem met de netwerk verbinding.',
            placement: 'bottomRight',
            type: 'error',
          });
          return;
        }
        if (error.response.data.message === 'Unauthorized') {
          return;
        } else {
          api.error({
            message: 'Er is iets fout gegaan',
            placement: 'bottomRight',
            type: 'error',
          });
        }
      });
    setFormItems(values);
    setIsModalOpen(true);
  };

  const sendData = async (data: {
    email: string;
    password: string;
    twoFactorAuthenticationCode: string;
  }) => {
    dispatch(
      login({
        email: data.email,
        password: data.password,
        twoFactorAuthenticationCode: data.twoFactorAuthenticationCode,
      }) as any,
    )
      .unwrap()
      .then((res: any) => {
        navigate('/dashboard');
      })
      .catch((reason: any) => {
        api.error({
          message: `${reason.message}`,
          placement: 'bottomRight',
          type: 'error',
        });
      });
  };

  interface Fields {
    email: string;
    password: string;
  }
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        backgroundColor: '#C8C6D8',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${BackgroundImageLogin})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      {contextHolder}
      <Col
        xs={24}
        md={12}
        lg={8}
        style={{
          backgroundColor: token.colorBgBase,
          border: `${token.colorBorder} 1px solid`,
          borderRadius: token.borderRadius,
          padding: 20,
          boxShadow: token.boxShadow,
        }}
      >
        <img
          src={CleaHrLogo}
          alt="CleaHR logo"
          style={{
            display: 'flex',
            margin: 'auto',
            width: 100,
            marginBottom: 20,
          }}
        />

        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          size={'middle'}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'Vul alstublieft een geldige email in.',
              },
              { required: true, message: 'Vul alstublieft een email in.' },
            ]}
          >
            <Input
              placeholder="email@provider.com"
              autoComplete="username"
              maxLength={2000}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Wachtwoord"
            rules={[
              {
                required: true,
                message: 'Vul alstublieft een wachtwoord in.',
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Wachtwoord"
              autoComplete="password"
              maxLength={2000}
            />
          </Form.Item>
          <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
            <Button type="link" onClick={() => navigate('/forgot-password')}>
              Wachtwoord vergeten?
            </Button>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        title="2 Factor Authenticatie"
        open={isModalOpen}
        closable={false}
        maskClosable={false}
        onOk={() =>
          sendData({
            email: formItems?.email ?? '',
            password: formItems?.password ?? '',
            twoFactorAuthenticationCode: inputValue,
          })
        }
        onCancel={() => {
          setIsModalOpen(false);
          setInputValue('');
        }}
      >
        <p>Voer uw 2FA code in.</p>
        <Form onFinish={sendData} form={form}>
          <OtpInput
            value={inputValue}
            onChange={(v) => {
              setInputValue(v);
              v.length === 6 &&
                sendData({
                  email: formItems?.email ?? '',
                  password: formItems?.password ?? '',
                  twoFactorAuthenticationCode: v,
                });
            }}
            containerStyle={{
              display: 'block',
              width: '100%',
              margin: '0 auto',
            }}
            shouldAutoFocus={true}
            numInputs={6}
            inputStyle={{
              marginTop: '0.5rem',
              width: '2rem',
              height: '2rem',
              margin: '0 0.5rem',
              fontSize: '2rem',
              textAlign: 'center',
              borderRadius: '4px',
              border: '1px solid #d9d9d9',
            }}
            renderInput={(props) => <input {...props} />}
          />
        </Form>
      </Modal>
    </Row>
  );
};

export default LoginScreen;
