import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth.slice';
import stepsReducer from './slices/steps.slice';
import { StepsState } from './slices/steps.slice';

export interface RootState {
  auth: any;
  steps: StepsState;
}

const reducer = {
  auth: authReducer,
  steps: stepsReducer,
};

export const store = configureStore({
  reducer,
});
