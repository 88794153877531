import { Result } from 'antd';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';

interface StatusMessages {
  [status: string]: {
    title: string;
    subtitle: string;
  };
}

const statusMessages: StatusMessages = {
  referentSuccess: {
    title: 'Bedankt voor het invullen van de referentie verklaring',
    subtitle:
      'Uw referentie verklaring is succesvol ingediend, u kunt deze pagina nu sluiten.',
  },
  confirmationSuccess: {
    title: 'Bedankt voor het invullen van de screening',
    subtitle:
      'Uw screening is succesvol ingediend, u kunt deze pagina nu sluiten.',
  },
  interpretationSuccess: {
    title: 'Bedankt voor het geven van duiding',
    subtitle:
      'De duiding is succesvol ingediend, u kunt deze pagina nu sluiten.',
  },
};

function SuccesPage() {
  useTitle('Succes');

  const { status } = useParams();

  if (!status) return <Navigate to="/404" />;
  if (!statusMessages[status]) return <Navigate to="/404" />;

  return (
    <Result
      status="success"
      title={statusMessages[status].title}
      subTitle={statusMessages[status].subtitle}
    />
  );
}

export default SuccesPage;
