import { Steps } from '../shared/enums/steps.enum';

export const availableSteps = [
  {
    key: Steps.personalData,
    title: 'Persoonlijke Info',
    url: Steps.personalData,
  },
  {
    key: Steps.identification,
    title: 'Legitimatie',
    url: Steps.identification,
  },
  {
    key: Steps.bankData,
    title: 'Bankgegevens',
    url: Steps.bankData,
  },
  {
    key: Steps.incomeCheck,
    title: 'Inkomenscheck',
    url: Steps.incomeCheck,
  },
  {
    key: Steps.kvk,
    title: 'KVK check',
    url: Steps.kvk,
  },
  {
    key: Steps.kadaster,
    title: 'Kadaster',
    url: Steps.kadaster,
  },
  {
    key: Steps.bkr,
    title: 'BKR',
    url: Steps.bkr,
  },
  {
    key: Steps.landlordStatement,
    title: 'Verhuurdersverklaring',
    url: Steps.landlordStatement,
  },
  {
    key: Steps.employeeIntegrity,
    title: 'Werknemersintegriteit',
    url: Steps.employeeIntegrity,
  },
  {
    key: Steps.education,
    title: 'Opleidingscheck',
    url: Steps.education,
  },
  {
    key: Steps.certificateOfConduct,
    title: 'Verklaring Omtrent Gedrag',
    url: Steps.certificateOfConduct,
  },
  {
    key: Steps.confirmation,
    title: 'Bevestiging',
    url: Steps.confirmation,
  },
];
