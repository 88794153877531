import React, { useEffect, useState } from 'react';
import {
  Input,
  Row,
  Table,
  Popconfirm,
  Button,
  InputNumber,
  notification,
} from 'antd';
import { axiosInstance } from '../../utils/axios';
import { EditOutlined } from '@ant-design/icons';
import { SaveTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';

interface DataSource {
  id: string;
  name: string;
  credits: number;
}

interface NewCredits {
  id: string;
  credits: number;
}

const CompanyTable: React.FC = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [filteredDataSource, setFilteredDataSource] =
    useState<DataSource[]>(dataSource);
  const [newCredits, setNewCredits] = useState<NewCredits[]>([]);

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    axiosInstance
      .get('/company/all')
      .then((res) => {
        setDataSource(res.data);
        setFilteredDataSource(res.data);
      })
      .finally(() => setLoadingData(false));
  }, []);

  function updateCredits(id: string, credits: number) {
    const updatedState = [...newCredits];
    const index = updatedState.findIndex((item) => item.id === id);

    if (index !== -1) {
      updatedState[index] = { id, credits };
    } else {
      updatedState.push({ id, credits });
    }
    setNewCredits(updatedState);
  }

  function getCredit(id: string) {
    const index = newCredits.findIndex((item) => item.id === id);
    if (index !== -1) {
      return newCredits[index].credits;
    } else {
      return -1;
    }
  }

  function defineAddCreditsDescription(oldCredits: number, newCredits: number) {
    const difference = oldCredits - newCredits;
    if (difference > 0) {
      return `Weet je zeker dat je ${
        difference === 1 ? '1 credit' : difference + ' credits'
      } wilt toevoegen?`;
    } else if (difference < 0) {
      return `Weet je zeker dat je ${
        difference === -1 ? '1 credit' : Math.abs(difference) + ' credits'
      } wilt verwijderen?`;
    } else {
      return `Weet je zeker dat je geen credits wilt toevoegen of verwijderen?`;
    }
  }

  function submitCredits(companyId: string, credits: number) {
    axiosInstance
      .post('company/update-credits', { companyId, credits })
      .then((res) => {
        const updatedDataSource = dataSource.map((item) => {
          if (item.id === companyId) {
            return { ...item, credits: credits };
          }
          return item;
        });
        setDataSource(updatedDataSource);
        setFilteredDataSource(updatedDataSource);
        notification.success({
          message: 'Credits zijn succesvol aangepast',
          placement: 'bottomRight',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Er is iets misgegaan',
          placement: 'bottomRight',
        });
      });
  }

  const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '40%',
    },
    {
      title: 'Expert',
      dataIndex: 'expertInterpretationEmail',
      key: 'expert',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Credits',
      dataIndex: 'credits',
      key: 'credits',
      width: '20%',
      sorter: (a: any, b: any) => Number(a.credits) - Number(b.credits),
      render: (credits: number, record: DataSource) => {
        return (
          <span key={record.id}>
            <InputNumber
              defaultValue={credits}
              min={0}
              onChange={(value) => {
                const newCredit = { id: record.id, credits: value ?? 0 };
                updateCredits(record.id, newCredit.credits);
              }}
            ></InputNumber>
            <Popconfirm
              title="Credits aanpassen"
              description={defineAddCreditsDescription(
                getCredit(record.id),
                credits,
              )}
              okText="Ja"
              cancelText="Nee"
              onConfirm={() => submitCredits(record.id, getCredit(record.id))}
              disabled={
                getCredit(record.id) === -1 || getCredit(record.id) === credits
              }
            >
              <Button
                type="text"
                style={{
                  display:
                    getCredit(record.id) === -1 ||
                    getCredit(record.id) === credits
                      ? 'none'
                      : 'inline-block',
                }}
              >
                <SaveTwoTone />
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (record: any) => {
        return (
          <div>
            <Button
              onClick={() => navigate(`/dashboard/company/edit/${record.id}`)}
              icon={<EditOutlined />}
              size="small"
              style={{ zIndex: 5 }}
            />
          </div>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    const filteredData = dataSource.filter((record) =>
      record.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDataSource(filteredData);
    if (value === '') setFilteredDataSource(dataSource);
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Row justify="end" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Input.Search
          placeholder="Zoek bedrijf"
          style={{ width: 250 }}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Row>
      <Table
        scroll={{ x: true }}
        dataSource={filteredDataSource}
        columns={columns}
        bordered
      />
    </div>
  );
};

export default CompanyTable;
