import { axiosInstance } from '../../utils/axios';
import BackgroundImageLogin from '../../assets/images/background-login.avif';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Divider, Form, Input, Row, message } from 'antd';
import { useTitle } from '../../hooks/useTitle';
import { validateEmail } from '../../utils/validators';

interface FormInput {
  password: string;
}

export default function ForgotPasswordPage() {
  useTitle('Wachtwoord vergeten');
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login');
  };

  const handleSubmit = async (values: FormInput) => {
    axiosInstance
      .post(`user/forgot-password`, values)
      .then((res) => {
        messageApi.success('Wachtwoord reset link verstuurd');
      })
      .catch((err) => {
        return messageApi.error('Er is iets misgegaan');
      });
  };

  const cardStyle: React.CSSProperties = {
    width: 500,
  };

  const mobileStyles: React.CSSProperties = {
    width: '100%',
  };

  if (window.innerWidth <= 768) {
    Object.assign(cardStyle, mobileStyles);
  }

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: '100vh',
        backgroundColor: '#C8C6D8',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${BackgroundImageLogin})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {contextHolder}
        <Card title="Wachtwoord vergeten?" style={cardStyle}>
          <div>
            <p>
              Wachtwoord vergeten? Geen zorgen! Voer uw e-mailadres in om een
              herstel link in uw mail te ontvangen. Met deze link kunt u uw
              wachtwoord opnieuw instellen. De link is 1 uur geldig. <br />
              <br />
              Let op! De mail wordt alleen verstuurd als het e-mailadres
              gekoppeld is aan een account.
            </p>
          </div>

          <Divider />

          <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item
              name="email"
              label="Emailadres"
              required
              rules={[
                {
                  validator: validateEmail,
                },
              ]}
            >
              <Input
                placeholder="email@provider.com"
                autoComplete="email"
                maxLength={2000}
              />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button onClick={handleClick}>Terug</Button>
              <Form.Item
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Verstuur
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </div>
    </Row>
  );
}
