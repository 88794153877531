import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';
import { axiosInstance } from '../../utils/axios';
import { uploadFile } from '../../utils/uploadFile';
import { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Typography,
  Upload,
  Divider,
  Card,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FileUrl } from '../../shared/types/url.interface';
import { useMessage } from '../../hooks/useMessage';

const { Text } = Typography;

function InterpretationPage() {
  useTitle('Duiding');
  const { token } = useParams();
  const navigate = useNavigate();
  const { toast } = useMessage();

  const [reportRequested, setReportRequested] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [downloadTries, setDownloadTries] = useState<number>(0);

  const [form] = Form.useForm();
  const [file, setFile] = React.useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    axiosInstance
      .get('/screening/interpretation/checkToken/' + token)
      .catch((error) => {
        if (
          error.response &&
          error.response.data.message === 'token is not found'
        ) {
          navigate('/error/interpretationNotFound');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (file) {
      let fileObject: FileUrl;
      await axiosInstance
        .get('/screening/interpretation/fileUrl/' + token)
        .then((response) => {
          fileObject = response.data;
        })
        .then(() => {
          if (!fileObject.url) {
            toast.error(
              'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.',
            );
            return;
          }

          setUploading(true);
          uploadFile(file, fileObject.url)
            .then(() => {
              axiosInstance
                .post('/screening/interpretation/saveFileUrl/' + token, {
                  path: fileObject.filePath,
                })
                .then(() => {
                  setUploading(false);
                  toast.success('Bestand opgeslagen');
                  navigate('/success/interpretationSuccess');
                });
            })
            .catch((error) => {
              toast.error(
                'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.',
              );
              setUploading(false);
            });
        })
        .catch((error) => {
          toast.error('Er is een fout opgetreden, probeer het later opnieuw.');
        });
    } else {
      toast.error('Upload alstublieft een pdf met duiding.');
    }
  };

  const onRequestReport = () => {
    setLoadingReport(true);

    axiosInstance
      .get('screening/interpretation/getReportByToken/' + token)
      .then((response) => {
        setLoadingReport(false);
        setReportRequested(true);

        setDownloadTries(downloadTries + 1);
        window.open(response.data[0]);
      })
      .catch((error) => {
        setLoadingReport(false);
        setReportRequested(true);
        toast.error('Er is iets misgegaan bij het genereren van het rapport');
      });
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ margin: '50px auto' }}>
        <Col xs={24} md={12} lg={8}>
          <Card title="Duiding op screening">
            <div>
              <Text>
                U bent gevraagd om duiding te geven bij een screening. Klik op
                de knop hieronder om de screening in te zien en daarna kunt u
                duiding geven bij deze screening door een pdf bestand te
                uploaden. Dit bestand zal dan worden toegevoegd aan het
                eindrapport.
              </Text>
            </div>
            <br></br>
            <Button
              loading={loadingReport}
              disabled={downloadTries >= 3}
              onClick={() => {
                onRequestReport();
              }}
            >
              Vraag concept rapport op
            </Button>

            {reportRequested && (
              <>
                <Divider />
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                  <Form.Item
                    label="PDF met duiding voor screening"
                    name="fileUpload"
                  >
                    <Upload
                      beforeUpload={(file) => {
                        setFile(file);
                        return false;
                      }}
                      onRemove={(file) => {
                        setFile(null);
                        return true;
                      }}
                      multiple={false}
                      maxCount={1}
                      accept=".pdf"
                    >
                      <Button icon={<UploadOutlined />}>
                        Selecteer bestand
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={uploading}
                    >
                      Opslaan
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default InterpretationPage;
