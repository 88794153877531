import { useState } from 'react';
import RentingQuestions from './RentingQuestions';
import GeneralQuestions from './GeneralQuestions';
import ReferentStep from './ReferentStep';
import { Steps } from 'antd';
import { useTitle } from '../../../hooks/useTitle';

export enum StepToShow {
  GENERAL = 'GeneralQuestions',
  RENTING = 'RentingQuestions',
  REFERENT = 'ReferentStep',
}

function LandLordStatementStep() {
  useTitle('Verhuurdersverklaring');
  const [stepToShow, setStepToShow] = useState(StepToShow.GENERAL);

  const updateStepDisabled = (stepEnum: StepToShow, disabled: boolean) => {
    setSteps((prevSteps) =>
      prevSteps.map((step) =>
        step.enum === stepEnum ? { ...step, disabled } : step,
      ),
    );
  };

  const [steps, setSteps] = useState([
    {
      title: 'Eigen verklaring - Algemeen',
      content: (
        <GeneralQuestions
          setStepToShow={setStepToShow}
          updateStepDisabled={updateStepDisabled}
        />
      ),
      enum: StepToShow.GENERAL,
    },
    {
      title: 'Eigen verklaring - Huurwoning',
      content: (
        <RentingQuestions
          setStepToShow={setStepToShow}
          updateStepDisabled={updateStepDisabled}
        />
      ),
      enum: StepToShow.RENTING,
      disabled: true,
    },
    {
      title: 'Referentie verklaring',
      content: <ReferentStep />,
      enum: StepToShow.REFERENT,
      disabled: true,
    },
  ]);

  return (
    <div style={{ maxWidth: 700 }}>
      <Steps
        progressDot
        current={steps.findIndex((step) => step.enum === stepToShow)}
        items={steps}
        onChange={(current) => setStepToShow(steps[current].enum)}
      />
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
        {steps.find((step) => step.enum === stepToShow)?.content}
      </div>
    </div>
  );
}

export default LandLordStatementStep;
