import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  message,
} from 'antd';
import { validateDateRange } from '../../utils/validators';
import { useTitle } from '../../hooks/useTitle';

interface ReferentInputs {
  periodOfRenting: string;
  latePayments: boolean;
  reasonOfLatePayments: string;
  comment: string;
}

export default function LandlordReferentPage() {
  useTitle('Referentie verklaring');
  const { token } = useParams();
  const [latePayments, setLatePayments] = useState<boolean>();
  const [renterName, setRenterName] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/screening/referent/${token}`)
      .then((response) => {
        setRenterName(response.data.name);
      })
      .catch((error) => {
        console.error(error);
        if (error.response?.status === 400) {
          if (error.response.data?.message === 'Referent not found') {
            return navigate('/error/referentNotFound');
          }
        }
      });
  }, [token, navigate]);

  const handleSubmit = async (values: ReferentInputs) => {
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_URL || '/api'
        }/screening/referent/info/${token}`,
        {
          periodOfRenting: values.periodOfRenting,
          latePayments: latePayments,
          reasonOfLatePayments: values.reasonOfLatePayments,
          comment: values.comment,
        },
      )
      .then(() => {
        navigate('/success/referentSuccess');
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          return messageApi.error('Ongeldige of al gebruikte link.');
        }
        messageApi.error('Er is iets misgegaan, probeer het later opnieuw');
      });
  };

  return (
    <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
      {contextHolder}
      <Card
        title="Referentie verklaring"
        style={{ marginTop: '25px', width: 500 }}
      >
        <div>
          <p>
            U staat op het punt een referentie verklaring in te vullen. Dit is
            een verklaring van een verhuurder over de huurder.
          </p>
          <span>
            Het gaat om de volgende huurder: <b>{renterName}</b>
          </span>
        </div>

        <Divider />

        <Form onFinish={handleSubmit} layout="vertical" form={form}>
          <Form.Item
            name="periodOfRenting"
            label="Welke periode heeft de huurder bij u gehuurd?"
            required
            rules={[
              {
                validator: validateDateRange,
              },
            ]}
          >
            <Input placeholder="Bijvoorbeeld 2020-2023" maxLength={2000} />
          </Form.Item>
          <Form.Item
            name="latePayments"
            label={`Heeft de huurder ooit te laat betaald bij u?`}
            rules={[
              {
                required: true,
                message:
                  'Vul alstublieft in of de huurder ooit te laat betaald heeft',
              },
            ]}
          >
            <Radio.Group
              value={latePayments}
              onChange={(event: RadioChangeEvent) =>
                setLatePayments(event.target.value)
              }
            >
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nee</Radio>
            </Radio.Group>
          </Form.Item>
          {latePayments && (
            <Form.Item
              name="reasonOfLatePayments"
              label="Reden te late betalingen"
              required
            >
              <Input
                placeholder="Bijvoorbeeld financiële problemen"
                maxLength={2000}
              />
            </Form.Item>
          )}

          <Form.Item name="comment" label="Opmerkingen">
            <Input.TextArea
              placeholder="Bijvoorbeeld overlast"
              rows={4}
              maxLength={2000}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Bevestigen
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
