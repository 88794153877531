import axios from 'axios';
export const uploadFile = async (file: File, signedUrl: string) => {
  const response = await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

  return response;
};
