import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CandidateAuthService } from '../services/candidateAuth.service';

interface UserState {
  isLoggedIn: boolean;
  candidate: any;
}

const storageCandidate = localStorage.getItem('candidate');
const candidate = storageCandidate ? JSON.parse(storageCandidate) : undefined;

export const verifyCode = createAsyncThunk(
  'candidate/verifyCode',
  async ({ code, token }: { code: string; token: string }, thunkAPI) => {
    try {
      const result = await CandidateAuthService.verifySmsCode(code, token);
      return { candidate: result };
    } catch (error) {
      if (error! instanceof AxiosError) {
        if (error.response?.data.statusCode === 401) {
          return thunkAPI.rejectWithValue({
            message: 'Code is onjuist',
          });
        }
        if (error.code === 'ERR_NETWORK') {
          return thunkAPI.rejectWithValue({
            message: 'Netwerk error, controleer je internet verbinding',
          });
        }
      }
      return thunkAPI.rejectWithValue({
        message: 'Er is iets misgegaan, probeer het later opnieuw',
      });
    }
  },
);

const initialState: UserState = candidate
  ? { isLoggedIn: true, candidate }
  : { isLoggedIn: false, candidate };

const candidateAuthSlice = createSlice({
  name: 'candidateAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      verifyCode.fulfilled,
      (state: UserState, action: PayloadAction<any>) => {
        state.isLoggedIn = true;
        state.candidate = action.payload.candidate;
      },
    );
    builder.addCase(verifyCode.rejected, (state: UserState) => {
      state.isLoggedIn = false;
      state.candidate = null;
    });
  },
});

export const authReducer = candidateAuthSlice.reducer;
