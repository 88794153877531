import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';
import CompanyTable from '../../../components/CompanyTable';

const CompanyDashboard = () => {
  useTitle('Bedrijven');
  return (
    <DashboardLayout>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <CompanyTable />
      </div>
    </DashboardLayout>
  );
};

export default CompanyDashboard;
