import { useState } from 'react';

import { Steps } from 'antd';
import { useTitle } from '../../../hooks/useTitle';
import ReferentStep from './ReferentStep';
import OwnStatement from './ownStatement';

export enum StepToShow {
  OWN = 'ownStatement',
  REFERENT = 'RefentStatement',
}

function EmployeeIntegrityStep() {
  useTitle('Werknemers integriteit');
  const [stepToShow, setStepToShow] = useState(StepToShow.OWN);

  const steps = [
    {
      title: 'Eigen verklaring',
      content: <OwnStatement setStepToShow={setStepToShow} />,
      enum: StepToShow.OWN,
    },
    {
      title: 'Referentie verklaring',
      content: <ReferentStep />,
      enum: StepToShow.REFERENT,
    },
  ];

  return (
    <div style={{ maxWidth: 500 }}>
      <Steps
        progressDot
        current={steps.findIndex((step) => step.enum === stepToShow)}
        items={steps}
        onChange={(current) => setStepToShow(steps[current].enum)}
      />
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
        {steps.find((step) => step.enum === stepToShow)?.content}
      </div>
    </div>
  );
}

export default EmployeeIntegrityStep;
