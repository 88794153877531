import { Spin } from 'antd';

const LoadingSpinner = () => {
  return (
    <Spin tip="Laden...">
      <div style={{ padding: 120 }} />
    </Spin>
  );
};

export default LoadingSpinner;
