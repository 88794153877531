import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Radio, Button, Divider } from 'antd';
import { StepToShow } from './index';
import { axiosInstance } from '../../../utils/axios';
import { useStep } from '../../../hooks/useStep';
import { useMessage } from '../../../hooks/useMessage';
import ownStatementQuestions from './ownStatementQuestions.json';
import LoadingSpinner from '../util/LoadingSpinner';
import CommentField from '../../CommentField/CommentField';
const { Paragraph } = Typography;

const questions = ownStatementQuestions.general;

interface FormValues {
  questions: {
    [questionName: string]: string;
  };
}
export default function OwnStatement({
  setStepToShow,
}: {
  setStepToShow: React.Dispatch<React.SetStateAction<StepToShow>>;
}) {
  const [form] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();
  const [comment, setComment] = useState<string>('');

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    questions: {},
  });

  const handleChange = (e: any, questionName: string) => {
    const newState = {
      ...formValues,
      questions: {
        ...formValues.questions,
        [questionName]: e.target.value,
      },
    };

    setFormValues(newState);

    const allQuestionsFilled = Object.values(newState.questions).every(
      (question) =>
        question !== undefined && question !== '' && question !== null,
    );
    if (allQuestionsFilled) {
      axiosInstance
        .post('/screening/employee-integrity/own-statement', newState.questions)
        .catch(() => {
          toast.error({
            content: `Er is een fout opgetreden, probeer het later opnieuw.`,
          });
        });
    }
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/employee-integrity/answers')
      .then((response) => {
        if (response.data) {
          setComment(response.data.comment);
          setFormValues({
            questions: {
              employerConflict: response.data.employerConflict,
              dismissedLastFiveYears: response.data.dismissedLastFiveYears,
              dismissedDueIntegrity: response.data.dismissedDueIntegrity,
              testedForInegrity: response.data.testedForInegrity,
              prosecutedForOffenceAtWork:
                response.data.prosecutedForOffenceAtWork,
              ableToRequestIntegrityAtPreviousEmployer:
                response.data.ableToRequestIntegrityAtPreviousEmployer,
            },
          });
          form.setFieldsValue({
            comment: response.data.comment,
          });
        }

        setLoadingData(false);
      })
      .catch(() => {
        setLoadingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    setLoading(true);
    const allQuestionsFilled = Object.values(formValues.questions).every(
      (question) => question !== undefined && question !== '',
    );
    if (!allQuestionsFilled) {
      setLoading(false);
      return toast.error({
        content: 'Niet alle vragen zijn beantwoord.',
      });
    }

    axiosInstance
      .post('/screening/employee-integrity/own-statement', formValues.questions)
      .then(() => {
        toast.success({
          content: 'Gegevens opgeslagen',
        });
        if (!formValues.questions.ableToRequestIntegrityAtPreviousEmployer) {
          goToNextStep();
        }
        setStepToShow(StepToShow.REFERENT);
      })
      .catch(() => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      <Form
        name="renting_questions"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        style={{ maxWidth: 500 }}
      >
        <Card title="Eigen verklaring">
          <Paragraph>
            Deze vragen worden gesteld om inzicht te krijgen in de integriteit
            van u als werknemer.
          </Paragraph>

          {questions.map((question) => (
            <Form.Item key={question.name}>
              <p>{question.question}</p>
              <Radio.Group
                onChange={(e) => handleChange(e, question.name)}
                value={formValues.questions[question.name]}
              >
                <Radio value={true}>Ja</Radio>
                <Radio value={false}>Nee</Radio>
              </Radio.Group>
            </Form.Item>
          ))}
          <Divider />
          <CommentField
            ApiUrl={'screening/employee-integrity/comment'}
            comment={comment}
          />
        </Card>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              float: 'right',
              marginTop: 20,
              marginBottom: 30,
              marginRight: 10,
            }}
            loading={loading}
          >
            Volgende
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
