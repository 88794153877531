import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Steps } from '../../shared/enums/steps.enum';
import { availableSteps } from '../../utils/allSteps';
export interface ScreeningStep {
  key: Steps;
  title: string;
  url: string;
}

export interface StepsState {
  availableSteps: ScreeningStep[];
  currentStep: number;
}

const initialState: StepsState = {
  availableSteps: availableSteps,
  currentStep: 0,
};

const stepsReducer = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    updateSteps: (state, action: PayloadAction<ScreeningStep[]>) => {
      state.availableSteps = action.payload;
    },
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
  },
});

export const { updateSteps, updateCurrentStep } = stepsReducer.actions;
export default stepsReducer.reducer;
