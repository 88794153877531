import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps, Dropdown, Menu, Button, Space, StepsProps, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { updateSteps, updateCurrentStep } from '../../redux/slices/steps.slice';
import { UnorderedListOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../utils/axios';
import '../../styles/screening.css';
import { ScreeningStep } from '../../redux/slices/steps.slice';
import PersonaStep from '../../components/screening-steps/PersonaStep';
import LegitimatieStep from '../../components/screening-steps/LegitimatieStep';
import Bankverification from '../../components/screening-steps/Bankverification';
import IncomeCheckStep from '../../components/screening-steps/IncomeCheckStep';
import KvkCheck from '../../components/screening-steps/KvkCheck';
import KadasterStep from '../../components/screening-steps/KadasterStep';
import BKRRegistrationStep from '../../components/screening-steps/BKRRegistrationStep';
import LandlordStatementStep from '../../components/screening-steps/LandlordStatementStep';
import ConfirmationStep from '../../components/screening-steps/ConfirmationStep';
import { Steps as StepsEnum } from '../../shared/enums/steps.enum';
import { availableSteps } from '../../utils/allSteps';
import EducationStep from '../../components/screening-steps/EducationStep';
import CertificateOfConductStep from '../../components/screening-steps/CertificateOfConductStep/CertificateOfConductStep';
import { useMessage } from '../../hooks/useMessage';
import EmployeeIntegrityStep from '../../components/screening-steps/EmployeeIntegrityStep';

interface SaveRef {
  save: () => void;
}

const Screening = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useMessage();
  const [loading, setLoading] = useState(false);
  const steps = useSelector((state: RootState) => state.steps.availableSteps);
  const current = useSelector((state: RootState) => state.steps.currentStep);

  const refs = {
    [StepsEnum.personalData]: useRef<SaveRef>(null),
    [StepsEnum.identification]: useRef<SaveRef>(null),
    [StepsEnum.bankData]: useRef<SaveRef>(null),
    [StepsEnum.incomeCheck]: useRef<SaveRef>(null),
    [StepsEnum.kvk]: useRef<SaveRef>(null),
    [StepsEnum.kadaster]: useRef<SaveRef>(null),
    [StepsEnum.bkr]: useRef<SaveRef>(null),
    [StepsEnum.landlordStatement]: useRef<SaveRef>(null),
    [StepsEnum.employeeIntegrity]: useRef<SaveRef>(null),
    [StepsEnum.confirmation]: useRef<SaveRef>(null),
    [StepsEnum.education]: useRef<SaveRef>(null),
    [StepsEnum.certificateOfConduct]: useRef<SaveRef>(null),
  };

  function renderCurrentStep(currentKey: string | undefined) {
    switch (currentKey) {
      case StepsEnum.personalData:
        return <PersonaStep ref={refs[StepsEnum.personalData]} />;
      case StepsEnum.identification:
        return <LegitimatieStep />;
      case StepsEnum.bankData:
        return <Bankverification />;
      case StepsEnum.incomeCheck:
        return <IncomeCheckStep ref={refs[StepsEnum.incomeCheck]} />;
      case StepsEnum.kvk:
        return <KvkCheck ref={refs[StepsEnum.kvk]} />;
      case StepsEnum.kadaster:
        return <KadasterStep ref={refs[StepsEnum.kadaster]} />;
      case StepsEnum.bkr:
        return <BKRRegistrationStep ref={refs[StepsEnum.bkr]} />;
      case StepsEnum.employeeIntegrity:
        return <EmployeeIntegrityStep />;
      case StepsEnum.landlordStatement:
        return <LandlordStatementStep />;
      case StepsEnum.education:
        return <EducationStep />;
      case StepsEnum.certificateOfConduct:
        return <CertificateOfConductStep />;
      case StepsEnum.confirmation:
        return <ConfirmationStep />;
    }
  }

  useEffect(() => {
    async function buildStepsArray(stepsArray: string[]) {
      const tempSteps: ScreeningStep[] = [];
      stepsArray.forEach((step, index) => {
        const stepItem = availableSteps.find((item) => item.key === step);
        if (!stepItem) return;
        tempSteps.push(stepItem);
      });
      dispatch(updateSteps(tempSteps));
      return tempSteps;
    }
    setLoading(true);
    axiosInstance
      .get('/screening/status/')
      .then((response) => {
        let stepIndex;
        buildStepsArray(response.data.steps).then((stepsArray) => {
          setLoading(false);
          if (response.data.status === 'notStarted') {
            axiosInstance.put(
              `/screening/update-status/${StepsEnum.personalData}`,
            );
            navigate(`/screening?step=${StepsEnum.personalData}`);
            stepIndex = 0;
          } else {
            navigate(`/screening?step=${response.data.status}`);
            stepIndex = stepsArray.findIndex(
              (step) => step.url === response.data.status,
            );
          }
          if (stepIndex !== -1) {
            dispatch(updateCurrentStep(stepIndex));
          }
          return;
        });
      })
      .catch((error) => {
        toast.open({
          type: 'error',
          content: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, toast]);

  function handleChangeStep(step: number) {
    const currentStep = steps[current].key;
    if (
      currentStep === StepsEnum.notStarted ||
      currentStep === StepsEnum.completed
    )
      return;

    refs[currentStep].current?.save();

    // Update the step-status
    axiosInstance.put(`/screening/update-status/${steps[step].url}`);
    navigate(`/screening?step=${steps[step].url}`);
    if (step !== -1) {
      dispatch(updateCurrentStep(step));
    }
  }
  const customDot: StepsProps['progressDot'] = (dot, { status, index }) => (
    <span>{dot}</span>
  );

  const hamburgerMenu = (
    <Menu>
      {steps.map((step, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleChangeStep(index)}
          style={current === index ? { color: 'blue' } : {}}
        >
          {step.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div className="screening-steps-dropdown">
        <Dropdown overlay={hamburgerMenu}>
          <Button>
            <Space>
              <UnorderedListOutlined />
              Steps
            </Space>
          </Button>
        </Dropdown>
      </div>
      <Spin spinning={loading} fullscreen />
      <div className="screening-steps">
        <Steps
          style={{ paddingTop: '20px' }}
          current={current}
          items={steps}
          onChange={handleChangeStep}
          progressDot={customDot}
        />
      </div>
      <div className="content">{renderCurrentStep(steps[current]?.key)}</div>
    </>
  );
};

export default Screening;
