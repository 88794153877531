import { Button, Tooltip, Typography, notification, theme } from 'antd';
import { axiosInstance } from '../../utils/axios';
import { useState } from 'react';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const { Title } = Typography;
const { useToken } = theme;

export default function SendNewInviteLink() {
  const [messageApi, contextHolder] = notification.useNotification();
  const { token } = useToken();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [showButtonIcon, setShowButtonIcon] = useState(false);

  async function sendNewInvite() {
    setLoading(true);
    axiosInstance
      .post(`/screening/resend-email/${id}`)
      .then(() => {
        setLoading(false);
        setShowButtonIcon(true);
        messageApi.success({
          message: 'Screening uitnodiging is opnieuw verstuurd',
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        setLoading(false);
        messageApi.error({
          message:
            'Er is iets fout gegaan bij het versturen van de uitnodiging. Probeer het later opnieuw.',
          placement: 'bottomRight',
        });
      });
  }

  return (
    <div>
      {contextHolder}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Title level={5} style={{ marginTop: '1rem' }}>
          Opnieuw versturen uitnodiging
        </Title>{' '}
        <Tooltip
          title={
            <div>
              <ul>
                <li>
                  Hiermee kunt u een nieuwe uitnodiging sturen naar de
                  kandidaat.
                </li>
                <li>
                  De nieuwe uitnodiging zal naar het e-mailadres van de
                  kandidaat worden gestuurd. De oude uitnodiging zal niet meer
                  werken.
                </li>
                <li>De nieuwe link is 30 dagen geldig.</li>
                <li>
                  <b>Let op:</b> Dit is niet nodig als u het emailadres heeft
                  aangepast, er zal automatisch een mail worden verstuurd.
                </li>
              </ul>
            </div>
          }
          overlayStyle={{ maxWidth: '30em' }}
          color={token.colorPrimary}
        >
          <InfoCircleOutlined style={{ marginLeft: '1%' }} />
        </Tooltip>
      </div>
      <Button onClick={sendNewInvite} loading={loading}>
        {showButtonIcon ? <CheckOutlined /> : null}
        Verstuur nieuwe uitnodiging
      </Button>
    </div>
  );
}
