import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../utils/axios';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentStep } from '../redux/slices/steps.slice';
import { RootState } from '../redux/store';

export const useStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screeningSteps = useSelector(
    (state: RootState) => state.steps.availableSteps,
  );

  const currentStep = useSelector(
    (state: RootState) => state.steps.currentStep,
  );

  const goToNextStep = async () => {
    if (currentStep < screeningSteps.length - 1) {
      await axiosInstance.put(
        `/screening/update-status/${screeningSteps[currentStep + 1].url}`,
      );
      dispatch(updateCurrentStep(currentStep + 1));
      navigate(`/screening?step=${screeningSteps[currentStep + 1].url}`);
    }
  };

  const goToPreviousStep = async () => {
    if (currentStep > 0) {
      await axiosInstance.put(
        `/screening/update-status/${screeningSteps[currentStep - 1].url}`,
      );
      dispatch(updateCurrentStep(currentStep - 1));
      navigate(`/screening?step=${screeningSteps[currentStep - 1].url}`);
    }
  };

  return { currentStep, goToNextStep, goToPreviousStep };
};
