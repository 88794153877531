import { Select } from 'antd';
import { SelectSteps } from '../../pages/dashboard/start-screening/start-screening';
import { Dispatch, SetStateAction } from 'react';

enum Presets {
  renter = 'renter',
  employeee = 'employee',
}

export function SelectPreset({
  setSelectSteps,
}: {
  setSelectSteps: Dispatch<SetStateAction<SelectSteps>>;
}) {
  const setAppropriateSelectedOptions = (option: string | undefined) => {
    switch (option) {
      case Presets.renter:
        setSelectSteps({
          identification: true,
          bankData: true,
          incomeCheck: true,
          kvk: true,
          bkr: true,
          kadaster: true,
          referent: true,
          landlordStatement: true,
          employeeIntegrity: false,
          education: false,
          certificateOfConduct: false,
        });
        break;
      case Presets.employeee:
        setSelectSteps({
          identification: true,
          bankData: true,
          incomeCheck: true,
          kvk: true,
          bkr: true,
          kadaster: false,
          referent: true,
          landlordStatement: false,
          employeeIntegrity: true,
          education: true,
          certificateOfConduct: true,
        });
        break;
      default:
        setSelectSteps({
          identification: false,
          bankData: false,
          incomeCheck: false,
          kvk: false,
          bkr: false,
          kadaster: false,
          referent: false,
          landlordStatement: false,
          employeeIntegrity: false,
          education: false,
          certificateOfConduct: false,
        });
        break;
    }
  };
  return (
    <div style={{ maxWidth: 250, width: '100%' }}>
      <label style={{ display: 'block', marginBottom: 5 }}>
        Selecteer type screening
      </label>
      <Select
        placeholder="Selecteer type screening"
        allowClear
        style={{ maxWidth: 250, width: '100%' }}
        onChange={(v) => setAppropriateSelectedOptions(v)}
        options={[
          { value: Presets.renter, label: 'Huurder' },
          { value: Presets.employeee, label: 'Werknemer' },
        ]}
      />
    </div>
  );
}
