import { axiosInstance } from '../../utils/axios';

const verifySmsCode = (code: string, token: string) => {
  return axiosInstance
    .post(
      '/candidate/auth/verify-code/',
      {
        code,
        token,
      },
      { withCredentials: true },
    )
    .then((response) => {
      if (response.data.candidate) {
        localStorage.removeItem('user');
        localStorage.setItem(
          'candidate',
          JSON.stringify(response.data.candidate),
        );
      }
      return response.data;
    });
};

const getCurrentCandidate = () => {
  const candidate = localStorage.getItem('candidate');
  if (!candidate) return;
  return JSON.parse(candidate);
};

const logout = () => {
  localStorage.removeItem('candidate');
};

export const CandidateAuthService = {
  verifySmsCode,
  logout,
  getCurrentCandidate,
};
