import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from '../services/auth.service';
import { AxiosError } from 'axios';

interface UserState {
  isLoggedIn: boolean;
  user: any;
}

const storageUser = localStorage.getItem('user');
const user = storageUser ? JSON.parse(storageUser) : undefined;

export const login = createAsyncThunk(
  'auth/login',
  async (
    {
      email,
      password,
      twoFactorAuthenticationCode,
    }: { email: string; password: string; twoFactorAuthenticationCode: string },
    thunkAPI,
  ) => {
    try {
      const result = await AuthService.login(
        email,
        password,
        twoFactorAuthenticationCode,
      );
      return { user: result };
    } catch (error) {
      if (error! instanceof AxiosError) {
        if (error.response?.data.statusCode === 400) {
          if (error.response?.data.message === 'Invalid two-factor code') {
            return thunkAPI.rejectWithValue({
              message: 'De twee-factor authenticatie code is onjuist',
            });
          }
        }
        if (error.response?.data.statusCode === 401) {
          return thunkAPI.rejectWithValue({
            message: 'Email of wachtwoord is onjuist',
          });
        }
        if (error.code === 'ERR_NETWORK') {
          return thunkAPI.rejectWithValue({
            message: 'Netwerk error, controleer je internet verbinding',
          });
        }
      }
      return thunkAPI.rejectWithValue({
        message: 'Er is iets misgegaan, probeer het later opnieuw',
      });
    }
  },
);

const initialState: UserState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (state: UserState, action: PayloadAction<any>) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      },
    );
    builder.addCase(login.rejected, (state: UserState) => {
      state.isLoggedIn = false;
      state.user = null;
    });
  },
});

export const authReducer = authSlice.reducer;
