import {
  Button,
  Divider,
  Form,
  Switch,
  Typography,
  Tooltip,
  theme,
  Select,
} from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { SelectSteps } from '../../pages/dashboard/start-screening/start-screening';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SelectPreset } from './SelectPreset';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const { Title } = Typography;
const { useToken } = theme;

interface SelectableSteps extends SelectSteps {
  referent: boolean;
}

interface SelectFormItems {
  name: string;
  key: keyof SelectableSteps;
  description: string;
}

const formItems: SelectFormItems[] = [
  {
    name: 'ID Check',
    key: 'identification',
    description:
      'Bij de ID-check wordt de identiteit van de kandidaat geverifieerd aan de hand van een officieel identiteitsbewijs met behulp van DataChecker.',
  },
  {
    name: 'Bank verificatie',
    key: 'bankData',
    description:
      'Bij de bank verificatie wordt het bankrekeningnummer van de kandidaat gecontroleerd door middel van een iDEAL betaling van € 0,01.',
  },
  {
    name: 'Inkomenscheck',
    key: 'incomeCheck',
    description:
      'Bij de inkomenscheck wordt de kandidaat gevraagd om 3 loonstroken, IB60 en de laatste jaaropgave te uploaden (bij zakelijke klanten is dit alleen 3 jaarcijfers).',
  },
  {
    name: 'KVK Check',
    key: 'kvk',
    description:
      'Bij de KvK check wordt er gevraagd om een KvK-nummer van het bedrijf van de kandidaat',
  },
  {
    name: 'BKR Check',
    key: 'bkr',
    description:
      'Bij de BRK check wordt er gevraagd om een screenshot op te sturen van de kandidaat zijn kredietgegevens bij stichting BKR.',
  },
  {
    name: 'Kadaster',
    key: 'kadaster',
    description:
      'Bij de Kadastercheck wordt er gevraagd of de kandidaat een geregistreerd object heeft bij het Kadaster en zo ja, om deze in te vullen.',
  },
  {
    name: 'Referentiecheck',
    key: 'referent',
    description:
      'Bij de referentiecheck wordt de kandidaat gevraagd om referenten toe te voegen. Deze referenten ontvangen een mail met een verzoek om een referentie te geven.',
  },
  {
    name: 'Opleidingscheck',
    key: 'education',
    description:
      'Bij de opleidingscheck wordt de kandidaat gevraagd naar de gevolgde en behaalde opleidingen en om een diploma te uploaden.',
  },
  {
    name: 'Verklaring Omtrent Gedrag',
    key: 'certificateOfConduct',
    description:
      'Bij de VOG check wordt aan de kandidaat gevraagd om toestemming te geven voor het aanvragen van een VOG. Deze zal vervolgens digitaal worden klaargezet bij Justis. De kandidaat ontvangt een betaallink en na betaling wordt de VOG toegestuurd, waarna deze kan worden geüpload.',
  },
];

function SelectScreeningSteps({
  selectSteps,
  setSelectSteps,
  allSelected,
  changeAllSelected,
}: {
  selectSteps: SelectSteps;
  setSelectSteps: Dispatch<SetStateAction<SelectSteps>>;
  allSelected: boolean;
  changeAllSelected: () => void;
}) {
  const { token } = useToken();
  const isMobile = useMediaQuery('(max-width: 480px)');

  function handleReferentType(value: string) {
    if (value === 'employeeIntegrity') {
      setSelectSteps((prev) => ({
        ...prev,
        referent: true,
        landlordStatement: false,
        employeeIntegrity: true,
      }));
    }
    if (value === 'landlordStatement') {
      setSelectSteps((prev) => ({
        ...prev,
        referent: true,
        landlordStatement: true,
        employeeIntegrity: false,
      }));
    }
  }

  const referentType = () => {
    if (selectSteps.employeeIntegrity) return 'employeeIntegrity';
    if (selectSteps.landlordStatement) return 'landlordStatement';
    return null;
  };

  return (
    <>
      <Title level={5} style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
        Selecteer Checks
      </Title>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <SelectPreset setSelectSteps={setSelectSteps} />

          <Button
            type="link"
            onClick={changeAllSelected}
            style={{ padding: 0, margin: 0, display: 'block' }}
          >
            {allSelected ? 'Deselecteer' : 'Selecteer'} alle checks
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      {formItems.map((item) => (
        <>
          {item.key === 'referent' ? (
            <Form.Item noStyle name={item.key}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {item.name}
                  <Tooltip
                    title={
                      <div>
                        <p>{item.description}</p>
                      </div>
                    }
                    overlayStyle={{ maxWidth: 'fit-content(<60>)' }}
                    color={token.colorPrimary}
                  >
                    <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                  </Tooltip>
                </div>
                <Switch
                  size="small"
                  checked={selectSteps[item.key]}
                  onChange={(value) => {
                    setSelectSteps((prev) => ({
                      ...prev,
                      [item.key]: value,
                    }));
                    if (!value)
                      setSelectSteps((prev) => ({
                        ...prev,
                        landlordStatement: false,
                        employeeIntegrity: false,
                      }));
                  }}
                />
              </div>
              {selectSteps.referent && (
                <Form.Item
                  required
                  label="Type van referentiecheck"
                  style={{ marginLeft: 40, marginTop: 8 }}
                >
                  <Select
                    style={{ width: '25%' }}
                    onChange={(v) => handleReferentType(v)}
                    value={referentType()}
                  >
                    <Select.Option value="employeeIntegrity">
                      Werknemers
                    </Select.Option>
                    <Select.Option value="landlordStatement">
                      Huurders
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Form.Item>
          ) : (
            <Form.Item noStyle name={item.key}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {item.name}
                  <Tooltip
                    title={
                      <div>
                        <p>{item.description}</p>
                      </div>
                    }
                    overlayStyle={{ maxWidth: 'fit-content(<60>)' }}
                    color={token.colorPrimary}
                  >
                    <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                  </Tooltip>
                </div>

                <Switch
                  size="small"
                  checked={selectSteps[item.key]}
                  onChange={(value) =>
                    setSelectSteps((prev) => ({
                      ...prev,
                      [item.key]: value,
                    }))
                  }
                />
              </div>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Form.Item>
          )}
        </>
      ))}
    </>
  );
}

export default SelectScreeningSteps;
