import { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Card,
  Typography,
  Input,
  Checkbox,
  Popconfirm,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../../utils/axios';
import { useStep } from '../../../hooks/useStep';
import { validateEmail, validatePhoneNumber } from '../../../utils/validators';
import { useMessage } from '../../../hooks/useMessage';
import LoadingSpinner from '../util/LoadingSpinner';

const { Paragraph } = Typography;

interface ReferentFields {
  referents: [
    {
      name: string;
      email: number;
      phoneNumber: string;
    },
  ];
  allowContact: boolean;
}

function ReferentStep() {
  const [form] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();

  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axiosInstance
      .get('/screening/employee-integrity/referents')
      .then((response) => {
        const { referents } = response.data;
        if (referents.length > 0) {
          const mappedReferents = referents.map((referent: any) => ({
            name: referent.name,
            email: referent.email,
            phoneNumber: referent.phoneNumber,
            role: referent.role,
            period: referent.period,
          }));
          const allowContact = referents[0].allowContact;
          form.setFieldsValue({ referents: mappedReferents, allowContact });
        }
      })
      .catch((err) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
      })
      .finally(() => setLoadingData(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: ReferentFields) => {
    setLoading(true);
    axiosInstance
      .post('/screening/employee-integrity/referents', values)
      .then(() => {
        toast.success({
          content: 'Gegevens opgeslagen',
        });
        goToNextStep();
      })
      .catch((error) => {
        setLoading(false);

        const { status, data } = error?.response;
        if (
          status === 400 &&
          data.message === 'You cannot add yourself as a referent'
        ) {
          return toast.error({
            content: 'U kunt uzelf niet als referent opgeven',
          });
        }
        toast.error({
          content: 'Er is een fout opgetreden, probeer het later opnieuw.',
        });
      });
  };

  const proceedWithoutReferents = () => {
    form.setFieldsValue({ referents: [] });
    goToNextStep();
  };

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          referents: [{}],
        }}
        style={{ maxWidth: 500 }}
      >
        <Card title="Referentie verklaring">
          <Paragraph>
            Wij vragen u hieronder de gegevens van uw (voormalige) werkgever in
            te vullen. Indien u meerdere werkgevers heeft kunt u deze met de
            knop onderaan toevoegen.
          </Paragraph>

          <Form.List name="referents">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: 'flex',
                  rowGap: 16,
                  flexDirection: 'column',
                }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`Referent ${field.name + 1}`}
                    key={field.key}
                    extra={
                      fields.length > 1 ? (
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null
                    }
                  >
                    <Form.Item
                      label="Naam"
                      name={[field.name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Vul alstublieft een naam in',
                        },
                      ]}
                    >
                      <Input maxLength={2000} />
                    </Form.Item>

                    <Form.Item
                      label="E-mailadres"
                      name={[field.name, 'email']}
                      rules={[
                        {
                          required: true,
                          message: 'Vul alstublieft een e-mailadres in',
                        },
                        { validator: validateEmail },
                      ]}
                    >
                      <Input type="email" maxLength={2000} />
                    </Form.Item>

                    <Form.Item
                      label="Telefoonnummer"
                      name={[field.name, 'phoneNumber']}
                      rules={[
                        {
                          required: true,
                          message: 'Vul alstublieft een telefoonnummer in',
                        },
                        { validator: validatePhoneNumber },
                      ]}
                    >
                      <Input type="phone" maxLength={50} />
                    </Form.Item>

                    <Form.Item
                      label="Welke functie bekleedde u bij deze werkgever?"
                      name={[field.name, 'role']}
                      rules={[
                        {
                          required: true,
                          message:
                            'Vul alstublieft in welke functie u bekleedde bij deze werkgever',
                        },
                      ]}
                    >
                      <Input maxLength={2000} />
                    </Form.Item>
                    <Form.Item
                      label="Welke periode heeft u bij deze werkgever gewerkt?"
                      name={[field.name, 'period']}
                      rules={[
                        {
                          required: true,
                          message:
                            'Vul alstublieft in welke periode u gewerkt heeft bij deze werkgever',
                        },
                      ]}
                    >
                      <Input
                        placeholder="April 2014 tot December 2022"
                        maxLength={1000}
                      />
                    </Form.Item>
                  </Card>
                ))}

                <Button type="dashed" onClick={() => add()} block>
                  + Referent toevoegen
                </Button>
              </div>
            )}
          </Form.List>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="allowContact"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Geef toestemming om contact op te nemen met de referent(en)',
                        ),
                      ),
              },
            ]}
          >
            <Checkbox style={{ marginTop: 10 }}>
              Ik geef toestemming om contact op te nemen met de opgegeven
              referent(en).
            </Checkbox>
          </Form.Item>
        </Card>

        <Popconfirm
          title="Weet u zeker dat u wilt door gaan zonder referenten?"
          description="Door geen referenten op te geven hebben wij minder compleet beeld"
          overlayStyle={{ width: 300 }}
          okText="Ja"
          cancelText="Nee"
          onConfirm={proceedWithoutReferents}
        >
          <Button
            style={{
              marginTop: 20,
              marginBottom: 30,
            }}
            type="link"
          >
            Doorgaan zonder referenten
          </Button>
        </Popconfirm>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
        >
          Volgende
        </Button>
      </Form>
    </>
  );
}

export default ReferentStep;
