import { Input } from 'antd';
import { axiosInstance } from '../../utils/axios';
import { useEffect, useState } from 'react';
import { useMessage } from '../../hooks/useMessage';
const { TextArea } = Input;

interface CommentFieldProps {
  ApiUrl: string;
  comment: string;
}

const CommentField = ({ ApiUrl, comment }: CommentFieldProps) => {
  const [commentValue, setCommentValue] = useState(comment);
  const { toast } = useMessage();

  useEffect(() => {
    setCommentValue(comment);
  }, [comment]);

  const saveComment = () => {
    axiosInstance
      .patch(ApiUrl, {
        comment: commentValue,
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleError = (error: any) => {
    switch (error.response.data.message) {
      case 'No existing datachecker data found':
        toast.error(
          'Er kan pas een opmerking worden toegevoegd als een legitimatie-check is aangevraagd.',
        );
        break;
      default:
        toast.error('Er is een fout opgetreden, probeer het later opnieuw.');
    }
  };

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      <label>Opmerking</label>
      <TextArea
        value={commentValue}
        onBlur={saveComment}
        onChange={(e) => setCommentValue(e.target.value)}
        maxLength={2000}
      />
    </div>
  );
};

export default CommentField;
