import parsePhoneNumberFromString, { PhoneNumber } from 'libphonenumber-js/max';
import validator from 'email-validator';

export const validatePhoneNumber = (
  rule: any,
  value: string,
  callback: any,
) => {
  if (!value) {
    return callback();
  }

  const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
    value,
    'NL',
  );

  if (!phoneNumber || !phoneNumber.isValid()) {
    return callback('Vul alstublieft een geldig mobiel nummer in.');
  }

  callback();
};

export const validateEmail = (rule: any, value: string, callback: any) => {
  if (!value) {
    return callback();
  }

  if (!validator.validate(value)) {
    return callback('Vul alstublieft een geldig emailadres in.');
  }

  callback();
};

export const validatePostcode = (rule: any, value: string, callback: any) => {
  if (!value) {
    return callback();
  }

  let re = new RegExp('^[0-9]{4}[A-Za-z]{2}$');

  if (!re.test(value)) {
    return callback('Vul alstublieft een geldige postcode in.');
  }

  callback();
};

export const validateDateRange = (rule: any, value: string, callback: any) => {
  const datePattern =
    /^(20\d{2}|19\d{2})\s*(-|tot|heden|nu)?\s*(19\d{2}|20\d{2}|21\d{2}|heden|nu)?$/;

  if (!datePattern.test(value)) {
    callback('Ongeldig datum format. Gebruik het format: 2018 - 2022');
  } else {
    callback();
  }
};

export const validatePassword = (rule: any, value: string, callback: any) => {
  if (!value) {
    return callback();
  }

  if (value.length < 8) {
    return callback('Het wachtwoord moet minimaal 8 karakters bevatten.');
  }

  if (value.length > 256) {
    return callback('Het wachtwoord mag maximaal 256 karakters bevatten.');
  }

  if (!value.match(/[a-z]/) || !value.match(/[A-Z]/) || !value.match(/[0-9]/)) {
    return callback(
      'Het wachtwoord moet minimaal 1 kleine letter, 1 hoofdletter en 1 cijfer bevatten.',
    );
  }

  callback();
};

export const validateKVK = (rule: any, value: string, callback: any) => {
  if (!value) {
    return callback();
  }

  let re = new RegExp('^[0-9]{8}$');

  if (!re.test(value)) {
    return callback('Geen geldig KVK nummer, vul alstublieft 8 cijfers in.');
  }

  callback();
};
