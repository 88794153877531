import { useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';

export function useDashBoardUserTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setTourSteps([
      {
        title: 'Gebruikers',
        description:
          'Hier ziet u een overzicht van alle gebruikers. U kunt gebruikers aanmaken, bewerken en verwijderen. Uw hoofdgebruiker is niet verwijderbaar, deze kan alleen verwijderd worden door het bedrijf te verwijderen.',
        target: getRef('users-table')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-users') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-users');
    }
  }, [isMobile, getRef]);

  return { tourSteps, setTourOpen, tourOpen };
}
