import axios from 'axios';
import { useContext } from 'react';
import { ErrorContext } from '../context/ErrorProvider';
import { AuthService } from '../redux/services/auth.service';

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const useAxiosInterceptor = () => {
  const { handleError } = useContext(ErrorContext);
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error?.response) {
        handleError('Er is een probleem met de netwerk verbinding.');
        await AuthService.logout().then(() => {
          window.location.href = '/login';
        });
        return Promise.reject(error);
      }
      if (error.response?.status === 401) {
        const user = localStorage.getItem('user');
        const candidate = localStorage.getItem('candidate');

        if (candidate && !user) {
          return refreshInstance
            .get('candidate/auth/refresh')
            .then(() => {
              return axiosInstance(error.config);
            })
            .catch(() => {
              localStorage.removeItem('candidate');
              window.location.href = '/error/loginCandidate';
            });
        }

        if (user && !candidate) {
          localStorage.removeItem('user');
          window.location.href = '/login';
        }

        if (!user && !candidate) {
          window.location.href = '/';
        }
      }
      if (
        error.response?.data?.message[0].includes(
          'must be shorter than or equal to 400 characters',
        ) &&
        !error.config?.error
      ) {
        handleError('Naam is te lang (max 400 karaketers)');
        return Promise.reject(error);
      }
      throw error;
    },
  );
};

export default useAxiosInterceptor;
