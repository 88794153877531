import {
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  notification,
} from 'antd';
import React, { useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import { validateEmail, validatePhoneNumber } from '../../utils/validators';

interface Referent {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  completed: boolean;
  periodOfRenting: string;
  referentStatus: string;
}

interface Item {
  key: string;
  name: string;
  email: string;
  phoneNumber: string;
  referentStatus: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
  type: 'phone' | 'email' | 'text';
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  type,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  const validator = {
    phone: validatePhoneNumber,
    email: validateEmail,
    text: (rule: any, value: any, callback: any) => {
      if (!value) {
        callback();
      }
      if (value.length < 2) {
        callback('De naam moet minimaal 2 tekens lang zijn.');
      }
      callback();
    },
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              validator: validator[type],
            },
            {
              required: true,
              message: `Vul alstublieft een ${title.toLowerCase()} in.`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function ReferentTable({
  data,
  setData,
  referentType,
}: {
  data: Referent[];
  setData: (data: Referent[]) => void;
  referentType: 'landlord' | 'employee' | null;
}) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [messageApi, contextHolder] = notification.useNotification();
  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', email: '', phoneNumber: '', ...record });
    setEditingKey(record.key);
  };

  data.forEach((referent) => {
    referent.referentStatus = referent?.completed
      ? 'Ingevuld'
      : 'Niet ingevuld';
  });

  const save = async (key: React.Key) => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      if (index <= -1) return;

      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });

      const newItem = newData[index];
      try {
        if (referentType === 'landlord') {
          await axiosInstance.put(`/screening/referent/${newItem.id}`, newItem);
        } else if (referentType === 'employee') {
          await axiosInstance.put(
            `/screening/employee-integrity/referent/${newItem.id}`,
            newItem,
          );
        }

        setData(newData);
        setEditingKey('');
      } catch (error: any) {
        if (error?.response?.data?.message === 'Referent already completed') {
          messageApi.error({
            message: 'Referent heeft de verklaring al ingevuld',
            placement: 'bottomRight',
          });
        } else {
          messageApi.error({
            message: 'Er is iets misgegaan bij het opslaan van de referent',
            placement: 'bottomRight',
          });
        }
      }
    } catch {
      messageApi.error({
        message: 'Er is iets misgegaan bij het opslaan van de referent',
        placement: 'bottomRight',
      });
    }
  };

  const sendMail = async (key: React.Key) => {
    const item = data.find((item) => {
      return key === item.id;
    });

    if (!item) return;

    axiosInstance
      .get(
        `${
          referentType === 'employee'
            ? `/screening/employee-integrity/referent/resend-mail/${item.id}`
            : `/screening/referent/resend-mail/${item.id}`
        }`,
      )
      .then(() => {
        messageApi.success({
          message: 'Email is opnieuw verstuurd',
          placement: 'bottomRight',
        });
      })
      .catch((error) => {
        if (error.response.data.message === 'Referent already completed') {
          messageApi.error({
            message: 'Referent heeft de verklaring al ingevuld',
            placement: 'bottomRight',
          });
        } else {
          messageApi.error({
            message:
              'Er is iets fout gegaan bij het opnieuw versturen van de email',
            placement: 'bottomRight',
          });
        }
      });
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      width: '25%',
      editable: true,
      type: 'text',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '25%',
      editable: true,
      type: 'email',
    },
    {
      title: 'Telefoonnummer',
      dataIndex: 'phoneNumber',
      width: '20%',
      editable: true,
      type: 'phone',
    },
    {
      title: 'Status',
      dataIndex: 'referentStatus',
      width: '15%',
      type: 'text',
    },
    {
      title: 'Acties',
      dataIndex: 'action',
      width: '17%',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex vertical>
            <div>
              <Popconfirm
                okText="Ja"
                cancelText="Nee"
                title="Weet u zeker dat u wilt opslaan?"
                description="
                  Als u het emailadres wijzigt, zal het nieuwe emailadres een
                  nieuwe uitnodiging ontvangen. De oude uitnodiging zal niet
                  meer werken."
                onConfirm={() => save(record.key)}
                overlayStyle={{ width: '250px' }}
              >
                <Typography.Link style={{ marginRight: 8 }}>
                  Opslaan
                </Typography.Link>
              </Popconfirm>
              <Popconfirm
                title="Weet u zeker dat u wilt annuleren?"
                okText="Ja"
                cancelText="Nee"
                onConfirm={cancel}
              >
                <Typography.Link>Annuleer</Typography.Link>
              </Popconfirm>
            </div>
            <Popconfirm
              title="Weet u zeker dat u de mail opnieuw wilt versturen?"
              okText="Ja"
              cancelText="Nee"
              onConfirm={() => sendMail(record.key)}
            >
              <Typography.Link disabled={record.referentStatus === 'Ingevuld'}>
                Opnieuw sturen
              </Typography.Link>
            </Popconfirm>
          </Flex>
        ) : (
          <Flex vertical>
            <Typography.Link
              disabled={
                editingKey !== '' || record.referentStatus === 'Ingevuld'
              }
              onClick={() => edit(record)}
              style={{ marginRight: 12 }}
            >
              Bewerken
            </Typography.Link>
            <Popconfirm
              title="Weet u zeker dat u de mail opnieuw wilt versturen?"
              okText="Ja"
              cancelText="Nee"
              onConfirm={() => sendMail(record.key)}
            >
              <Typography.Link
                disabled={
                  editingKey !== '' || record.referentStatus === 'Ingevuld'
                }
              >
                Opnieuw sturen
              </Typography.Link>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        type: col.type,
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      {contextHolder}
      <Table
        dataSource={data.map((referent) => ({
          ...referent,
          key: referent.id,
        }))}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        pagination={false}
        columns={mergedColumns}
      />
    </Form>
  );
}

export default ReferentTable;
